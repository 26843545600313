import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FaApple, FaWindows, FaClock, FaTimes,FaVideo, FaCamera, FaQuestionCircle, FaRegSmile, FaTrash, FaShareAlt, FaUnlink, FaArrowRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import "../App.css"; // Ensure global styles are applied
import FAQ from "./FAQ";

const Landing = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [demoModalMainOpen, setDemoModalMainOpen] = useState(false);
    

    useEffect(() => {
        if (user) {
          navigate('/homepage');
        }
      }, [user, navigate]);
    

    
  return (
    <div className="landing-container">
      {/* Hero Section */}
      <section className="section alternate-section">
        <div className="section-content-wrapper">
          <div className="section-content">
            <h1 className="hero-title">Train Smarter with Victorise</h1>
            <p className="hero-description">
              Compare runs, overlay data on video, and analyze your performance—all in one place.
            </p>
            <p className="hero-description">
               <button 
                  style={{ 
                    background: "none", 
                    border: "none", 
                    color: "#fff", 
                    cursor: "pointer", 
                    fontSize: "16px" 
                  }}
                  onClick={() => setDemoModalMainOpen(true)}
                >
                  <FaVideo style={{ marginRight: "5px" }} /> view demo video
                </button>
            </p>
            {/* Buttons for Login & Signup */}
            <div className="auth-buttons">
  <Link to="/login" className="auth-link">
    <button className="auth-btn outlined">Sign In</button>
  </Link>
  <Link to="/login?signup=true" className="auth-link">
    <button className="auth-btn filled">Create Free Account</button>
  </Link>
</div>
          </div>
          <div className="section-image">
            <img src="img/laptop.png" alt="Comparison Illustration" />
          </div>
        </div>
      </section>

      {/* Alternating Sections */}
      <section className="section">
        <div className="section-content-wrapper">
          <div className="section-image">
            <img src="img/compare.png" alt="Comparison Illustration" />
          </div>
          <div className="section-content">
            <h2 className="section-title">Compare Runs with Video & Data</h2>
            <p className="section-description">
              See the differences. Understand the why. Victorise lets you analyze multiple runs side by side, combining video and data for better race insights.
            </p>
          </div>
        </div>
      </section>

      <section className="section alternate-section">
        <div className="section-content-wrapper">
          <div className="section-content">
            <h2 className="section-title">Overlay Data on Video</h2>
            <p className="section-description">
              Visualize performance like never before. Add braking, GPS, split times, and key metrics directly to your video for a clearer breakdown of what happened on track.
            </p>
          </div>
          <div className="section-image">
            <img src="img/overlay.png" alt="Overlay Illustration" />
          </div>
        </div>
      </section>

      <section className="section">
        <div className="section-content-wrapper">
          <div className="section-image">
            <img src="/img/datasource.png" alt="Data Sources Illustration" />
          </div>
          <div className="section-content">
            <h2 className="section-title">Works with Your Existing Data</h2>
            <p className="section-description">
              Seamless integration with top data sources. Victorise supports Brake Ace, BYB data acquisition, Fit files, GPX, and GoPro GPS data, making it easy to bring your performance data into one place.
            </p>
          </div>
        </div>
      </section>

      <section className="section alternate-section">
        <div className="section-content-wrapper">
          <div className="section-content">
            <h2 className="section-title">Share & Collaborate</h2>
            <p className="section-description">
              Racing is a team effort. Share your runs with teammates, coaches, or analysts to review insights together and make better decisions.
            </p>
          </div>
          <div className="section-image">
            <img src="/img/collab.png" alt="Collaboration Illustration" />
          </div>
        </div>
      </section>

      {/* Final Call-to-Action */}
      <section className="section">
        <div className="section-content-wrapper">
          <div className="section-image">
            <img src="/img/letsgo.png" alt="Get Started Illustration" />
          </div>
          <div className="section-content">
            <h2 className="section-title">Get Started Today</h2>
            <p className="section-description">
              Victorise is now available. Ready to analyze your performance?
            </p>
            <Link to="/login?signup=true" className="primary-btn">
              Create an Account
            </Link>
          </div>
        </div>
      </section>

      <section className="section alternate-section" style={{ backgroundColor: "#dadada" }}>    
        <div className="section-content-wrapper">
          <div className="section-content">
            <h2 className="section-title">FAQ</h2>
            <FAQ />
          </div>
        </div>
      </section>


      <section className="section">
        <div className="section-content-wrapper">
          <div className="section-content">
 {/* Download Desktop Tools Section */}
 <div className="homepage-downloads">
 <h2 className="section-title"> Download Desktop Tools</h2>
   <p className="homepage-paragrah">
     Our online tool can extract data from your GoPro file, but transferring large files over the internet can be time consuming and demands a strong connection.
     That’s why we provide a desktop version that extracts your GoPro data locally—faster, more reliable, and completely offline.
   </p>
   
   <div className="download-links">
  <a 
    href="download/macos_process_gopro_desktop.zip" 
    className="download-link"
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FaApple style={{ marginRight: "10px", fontSize: "24px" }} />
    Download for macOS
  </a>

  <a 
    href="download/windows_process_gopro_desktop.zip" 
    className="download-link"
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FaWindows style={{ marginRight: "10px", fontSize: "24px" }} />
    Download for Windows
  </a>
</div>

   <br/><hr/>
   <p style={{ marginTop: "20px", fontSize: "16px" }} className="homepage-paragrah">
     Also, check out:<br/>
     <div className="download-links">
     <a 
       href="https://chronocult.com" 
       target="_blank" 
      className="download-link"
       rel="noopener noreferrer" 
       style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
     >
       <FaClock style={{ marginRight: "10px", fontSize: "28px" }}/> Chronocult
     </a></div>
     <br/>Chronocult is a powerful and easy-to-use timing system that lets you effortlessly track and manage your performance data.
     With its intuitive interface and comprehensive features, Chronocult helps athletes, coaches, and enthusiasts accurately record split times,
     lap durations, and overall performance metrics. Whether you’re timing a race, a training session, or a cycling event, Chronocult
     delivers reliable and detailed timing information that you can easily export and analyze. Explore Chronocult to experience a seamless
     blend of simplicity and functionality, designed to elevate your performance tracking to the next level.
   </p>
 </div>
 </div></div>
 </section>
 {demoModalMainOpen && (
  <div className="modal-overlay" onClick={() => setDemoModalMainOpen(false)}>
    <div className="modal" onClick={(e) => e.stopPropagation()} style={{ position: 'relative', width: '80%' }}>
      {/* New Close Button (Cross Icon) in the Top Right */}
      <button className="modal-close-btn" onClick={() => setDemoModalMainOpen(false)}>
        <FaTimes />
      </button>
      <h3>Demo Video – Video/Data Analysis Tool</h3>
      <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
        <iframe 
          title="Demo Video" 
          src="https://www.youtube.com/embed/VVgDmG3AIX8" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen 
          style={{ position: "absolute", top:0, left:0, width:"100%", height:"100%" }}
        />
      </div>
    </div>
  </div>
)}


    </div>
  );
};

export default Landing;