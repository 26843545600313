import React, { useCallback, useState, useEffect, useRef } from "react";
import Dashboard from "./Dashboard";
import { MapProvider } from "./MapProvider";
import { auth, db } from "../firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  query,
  where, 
} from "firebase/firestore";
import creditCosts from "../config/creditCosts";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCamera, FaEye, FaEyeSlash, FaVideo, FaLink,FaUnlink } from "react-icons/fa";
import SegmentCreator from "./SegmentCreator";
import Tooltip from "./Tooltip";
import { createOrUpdateMarker } from './SegmentCreator';
import { handleAutoSplit } from '../utils/autoSplitHelper';

// Helper: Extract YouTube Video ID from URL (assumes standard URL format)
const extractYouTubeVideoID = (url) => {
  const regExp =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/;
  const match = url.match(regExp);
  return match ? match[1] : "";
};

// Your color library
const colorLibrary = [
  "#FF69B4", // Pink
  "#FF4500", // Orange
  "#FFD700", // Yellow
  "#1E90FF", // Bright Blue
  "#40E0D0", // Turquoise
  "#ADFF2F", // Green-Yellow
  "#32CD32", // Lime Green
];
// Store shuffled colors
let shuffledColors = [...colorLibrary].sort(() => Math.random() - 0.5);
let colorIndex = 0;
const getRandomColor = () => {
  const color = shuffledColors[colorIndex];
  colorIndex++;
  if (colorIndex >= shuffledColors.length) {
    shuffledColors = [...colorLibrary].sort(() => Math.random() - 0.5);
    colorIndex = 0;
  }
  return color;
};

const MultiDashboard = () => {
  const user = auth.currentUser;
  const navigate = useNavigate();
  const locationObj = useLocation(); // renamed to avoid conflict
  const [dashboards, setDashboards] = useState([]);
  const [masterSegments, setMasterSegments] = useState([]);
  const [credits, setCredits] = useState(0);
  const [runs, setRuns] = useState([]); // Store available runs
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredRuns, setFilteredRuns] = useState([]);
  const [sortType, setSortType] = useState("date");
  const [searchTerm, setSearchTerm] = useState("");
  const autoLoadTriggeredRef = useRef(false); // ref for auto-load
  const [segments, setSegments] = useState([]);
  const [segmentCounter, setSegmentCounter] = useState(1);
  const [masterVideoRef, setMasterVideoRef] = useState(null);
  const [masterTelemetry, setMasterTelemetry] = useState([]);
  const [showSegmentsPanel, setShowSegmentsPanel] = useState(true);
  const [masterTime, setMasterTime] = useState(null);
  const [videosLinked, setVideosLinked] = useState(false);
  const [videoRefs, setVideoRefs] = useState({});
  const markerRefs = useRef({});
  // Add this below your state declarations, e.g., after [videoRefs, setVideoRefs] definition.
const syncVideosToTime = (newTime) => {
  // console.log("Syncing videos to time:", newTime);
  Object.keys(videoRefs).forEach((dId) => {
    const videoData = videoRefs[dId];
    if (videoData) {
      if (videoData.type === "local" && videoData.refOrPlayer?.current) {
        // console.log(`Seeking local video in dashboard ${dId} to ${newTime}s`);
        videoData.refOrPlayer.current.currentTime = newTime;
      } else if (videoData.type === "youtube" && videoData.refOrPlayer) {
        console.log(`Seeking YouTube video in dashboard ${dId} to ${newTime}s`);
        // Using YouTube API's seek method
        videoData.refOrPlayer.seekTo(newTime, true);
      }
    }
  });
};
const handleMasterTimeChange = useCallback((newTime) => {
  setMasterTime(newTime);
}, []);

  const handleMasterVideoRefReady = useCallback((vidRef) => {
    setMasterVideoRef((prevRef) => (prevRef === vidRef ? prevRef : vidRef));
  }, []);


  const canAddSegments = dashboards.some(
    (d) => d.runData?.telemetryData && d.runData.telemetryData.length > 0
  );

  const toggleSegmentsVisibility = () => {
    setShowSegmentsPanel((prev) => !prev);
  };

  const registerVideoRef = useCallback((dashboardId, refOrPlayer, videoType) => {
    setVideoRefs(prev => {
      const newRefs = { ...prev, [dashboardId]: { type: videoType, refOrPlayer } };
      console.log("📌 Updated videoRefs:", newRefs);
  
      // ✅ Store videoRefs globally for debugging
      window.videoRefs = newRefs;
  
      return newRefs;
    });
  }, []);

  const syncVideoPlay = (originDashboardId) => {
    if (!videosLinked) return;
  
    console.log("🔄 Syncing video play across dashboards:", Object.keys(videoRefs));
  
    Object.keys(videoRefs).forEach((dId) => {
      const videoData = videoRefs[dId];
  
      if (dId !== originDashboardId.toString() && videoData) {
        if (videoData.type === "local" && videoData.refOrPlayer?.current) {
          console.log(`▶️ Playing local video in dashboard ${dId}`);
          videoData.refOrPlayer.current.play();
        } else if (videoData.type === "youtube" && videoData.refOrPlayer) {
          console.log(`▶️ Playing YouTube video in dashboard ${dId}`);
          videoData.refOrPlayer.playVideo();
        } else {
          console.warn(`⚠️ No valid video found for dashboard ${dId}`);
        }
      }
    });
  };
  
  const syncVideoPause = (originDashboardId) => {
    if (!videosLinked) return; // ✅ Only sync if videos are linked
    Object.keys(videoRefs).forEach((dId) => {
      if (dId !== originDashboardId.toString()) {
        const videoData = videoRefs[dId];
        if (videoData) {
          if (videoData.type === "local" && videoData.refOrPlayer?.current) {
            videoData.refOrPlayer.current.pause();
          } else if (videoData.type === "youtube" && videoData.refOrPlayer) {
            videoData.refOrPlayer.pauseVideo();
          }
        }
      }
    });
  };

  // near the top, after `const [dashboards, setDashboards] = useState([]);`
  const handleChildTelemetry = (dashboardId, newData) => {
    setDashboards(prevDashboards =>
      prevDashboards.map(d => {
        if (d.id === dashboardId) {
          if (JSON.stringify(d.runData.telemetryData) === JSON.stringify(newData)) {
            return d; // No change
          }
          return { ...d, runData: { ...d.runData, telemetryData: newData } };
        }
        return d;
      })
    );
  };

  const handleMasterTelemetry = useCallback((telemetryArray) => {
    setMasterTelemetry(telemetryArray);
  }, []);



  useEffect(() => {
    window.syncVideoPlay = syncVideoPlay;
    window.syncVideoPause = syncVideoPause;
    window.videoRefs = videoRefs;
  }, [syncVideoPlay, syncVideoPause, videoRefs]);


  // Fetch user credits on mount
  useEffect(() => {
    console.log("1");
    
    const fetchCredits = async () => {
      const user = auth.currentUser;
      if (!user) return;
      const docRef = doc(db, "users", user.uid);
      const snapshot = await getDoc(docRef);
      if (snapshot.exists()) {
        setCredits(snapshot.data().credits || 0);
      } else {
        console.warn("User doc not found in Firestore.");
      }
    };
    fetchCredits();
  }, []);

  useEffect(() => {
    console.log("2");
    setSegments((prevSegments) => {
        const updatedSegments = prevSegments.map((seg) => recalcSegmentDurations(seg));
        return JSON.stringify(updatedSegments) === JSON.stringify(prevSegments) 
            ? prevSegments 
            : updatedSegments;
    });
}, [dashboards]);

  // Auto-fetch runs if a runId is provided in the URL and runs haven't been fetched yet
  useEffect(() => {
    console.log("3");
    const params = new URLSearchParams(locationObj.search);
    const runId = params.get("runId");
  
    if (runId && runs.length === 0) {
      const fetchRunsIfNeeded = async () => {
        try {
          const runsCollection = collection(db, "runs");
          const qOwned = query(runsCollection, where("userId", "==", user.uid));
          const snapshotOwned = await getDocs(qOwned);
  
          const ownedRuns = snapshotOwned.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            date: doc.data().timestamp?.seconds
              ? new Date(doc.data().timestamp.seconds * 1000).toLocaleString()
              : "Unknown Date",
          }));
  
          const uniqueRuns = Array.from(new Map(ownedRuns.map(run => [run.id, run])).values());
  
          console.log("✅ Auto-fetch: runs fetched:", uniqueRuns.length);
  
          // ✅ Update state ONLY if data is different
          setRuns(prevRuns => JSON.stringify(prevRuns) !== JSON.stringify(uniqueRuns) ? uniqueRuns : prevRuns);
  
        } catch (error) {
          console.error("Error auto-fetching runs:", error);
        }
      };
  
      fetchRunsIfNeeded();
    }
  }, [locationObj.search]); // ✅ Remove `runs.length` to prevent looping
/////////////////////////////////////////

  // Handle adding a new dashboard


  const handleAddSegment = () => {
    if (dashboards.length === 0) {
      alert("You must have at least one dashboard loaded to create segments!");
      return;
    }
    const rndColor = getRandomColor();
    const newSeg = {
      id: Date.now(),
      color: rndColor,
      name: `S${segmentCounter}`,
      startFrame: null,
      endFrame: null,
      startGPS: null,
      endGPS: null,
      durations: {},
    };
    setSegments((prev) => [...prev, newSeg]);
    setSegmentCounter((prev) => prev + 1);
    setShowSegmentsPanel(true);
  };
  const handleUpdateSegment = (updatedSeg) => {
    console.log("handleUpdateSegment called. segment.id =", updatedSeg.id);
    const newSeg = recalcSegmentDurations(updatedSeg);
    setSegments((prev) =>
      prev.map((seg) => (seg.id === newSeg.id ? newSeg : seg))
    );
  };
  
  const handleDeleteSegment = (segmentId) => {
    setSegments((prev) => prev.filter((seg) => seg.id !== segmentId));
  };

  const addDashboard = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert("Please log in first.");
      return;
    }
    if (credits <= 0) {
      alert("You have no credits left! Please purchase more.");
      return;
    }
    try {
      const docRef = doc(db, "users", user.uid);
      await updateDoc(docRef, { credits: credits - creditCosts.ADD_RUN });
      setCredits((prev) => prev - 1);
      setDashboards((prev) => [
        ...prev,
        {
          id: Date.now(),
          color: getRandomColor(),
          runData: {
            youtubeUrl: null,
            uploadedToYouTube: false,
            videoFileName: null,
            telemetryData: [],
            metrics: {},
          },
        },
      ]);
    } catch (err) {
      console.error("Error updating credits:", err);
      alert("Something went wrong while updating credits.");
    }
  };

  // Handle removing a dashboard
  const removeDashboard = (id) => {
    setDashboards((prev) => prev.filter((d) => d.id !== id));
  };

  // Fetch runs from Firestore when clicking "Load Run"

  const fetchRuns = async () => {
    try {
      const runsRef = collection(db, "runs");
      
      // Query 1: Runs you own
      const qOwned = query(runsRef, where("userId", "==", user.uid));
      const snapshotOwned = await getDocs(qOwned);
      const ownedRuns = snapshotOwned.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.timestamp?.seconds
            ? new Date(data.timestamp.seconds * 1000).toLocaleString()
            : "Unknown Date",
        };
      });
  
      // Query 2: Runs shared with you
      const qShared = query(runsRef, where("sharedWith", "array-contains", user.uid));
      const snapshotShared = await getDocs(qShared);
      const sharedRunsList = snapshotShared.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.timestamp?.seconds
            ? new Date(data.timestamp.seconds * 1000).toLocaleString()
            : "Unknown Date",
        };
      });
  
      // Combine both arrays; if a run is owned and shared, we can remove duplicates.
      // For simplicity, we'll combine and then filter by unique id.
      const combined = [...ownedRuns, ...sharedRunsList];
      const uniqueRuns = Array.from(new Map(combined.map(run => [run.id, run])).values());
  
      console.log("✅ Auto-fetch: runs fetched:", uniqueRuns.length);
      setRuns(uniqueRuns);
      setFilteredRuns(uniqueRuns);
    } catch (error) {
      console.error("Error auto-fetching runs:", error);
    }
  };

  // Handle selecting a run and creating a new dashboard instance
  const loadRun = async (selectedRun) => {
    setIsModalOpen(false);
    const user = auth.currentUser;
    if (!user) {
      alert("Please log in first.");
      return;
    }
    if (credits < creditCosts.LOAD_RUN) {
      alert("Not enough credits to load a run.");
      return;
    }
    try {
      const docRef = doc(db, "users", user.uid);
      await updateDoc(docRef, { credits: credits - creditCosts.LOAD_RUN });
      setCredits((prev) => prev - creditCosts.LOAD_RUN);
      setDashboards((prev) => [
        ...prev,
        {
          id: Date.now(),
          color: getRandomColor(),
          runData: selectedRun,
        },
      ]);
    } catch (err) {
      console.error("Error updating credits:", err);
      alert("Something went wrong while deducting credits.");
    }
  };

  // Sort runs by name or date
  const sortRuns = (type) => {
    setSortType(type);
    const sortedRuns = [...filteredRuns].sort((a, b) => {
      if (type === "name") return a.runName.localeCompare(b.runName);
      if (type === "date") return new Date(b.date) - new Date(a.date);
      return 0;
    });
    setFilteredRuns(sortedRuns);
  };

  // Filter runs based on search input
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = runs.filter((run) =>
      run.runName.toLowerCase().includes(value)
    );
    setFilteredRuns(filtered);
  };

  // Auto-load Effect: load run if runId exists in URL
  useEffect(() => {
    console.log("4");
    const params = new URLSearchParams(locationObj.search);
    const runId = params.get("runId");
    console.log("Auto-load: runId from URL =", runId);
    if (runId && runs.length > 0 && !autoLoadTriggeredRef.current) {
      const runToLoad = runs.find((r) => r.id === runId);
      if (runToLoad) {
        autoLoadTriggeredRef.current = true;
        loadRun(runToLoad);
        params.delete("runId");
        navigate(`${locationObj.pathname}?${params.toString()}`, { replace: true });
      } else {
        console.warn("No matching run found for runId:", runId);
      }
    }
  }, [runs, locationObj.search, locationObj.pathname, navigate]);

  function findNearestTime(telemetryArray, targetTime) {
    let closest = null;
    let minDelta = Infinity;
    for (const point of telemetryArray) {
      const delta = Math.abs(point.time - targetTime);
      if (delta < minDelta) {
        minDelta = delta;
        closest = point;
      }
    }
    return closest;
  }
  const recalcSegmentDurations = (segment) => {
    const updatedSegment = { ...segment, durations: { ...segment.durations } };
    
    // Only recalc if both start and finish have been set in the master.
    if (segment.startGPS && segment.endGPS) {
      dashboards.forEach((dashboard, index) => {
        // If this dashboard is the master, simply copy the master times.
        if (index === 0) {
          updatedSegment.durations[dashboard.id] = {
            startTime: segment.startFrame,
            endTime: segment.endFrame,
            duration: Math.abs(segment.endFrame - segment.startFrame),
          };
        } else {
          // For each child dashboard, use its telemetry data to find the nearest times.
          const telem = dashboard.runData?.telemetryData;
          if (!telem || telem.length === 0) {
            updatedSegment.durations[dashboard.id] = { startTime: null, endTime: null, duration: 0 };
          } else {
            // Find the telemetry point closest to the master's startGPS
            const startPoint = findNearestLatLonPoint(telem, segment.startGPS.lat, segment.startGPS.lon);
            // Find the telemetry point closest to the master's endGPS
            const endPoint = findNearestLatLonPoint(telem, segment.endGPS.lat, segment.endGPS.lon);
            let dashDuration = 0;
            if (startPoint && endPoint) {
              dashDuration = Math.abs(endPoint.time - startPoint.time);
            }
            updatedSegment.durations[dashboard.id] = {
              startTime: startPoint ? startPoint.time : null,
              endTime: endPoint ? endPoint.time : null,
              duration: dashDuration,
            };
          }
        }
      });
    } else {
      // If startGPS or endGPS is not set, zero out durations.
      dashboards.forEach((dashboard) => {
        updatedSegment.durations[dashboard.id] = { startTime: null, endTime: null, duration: 0 };
      });
    }
    
    return updatedSegment;
  };
  
  // Example lat/lon helper:
  const findNearestLatLonPoint = (telemetry, lat, lon) => {
    let closest = null;
    let minDist = Infinity;
    telemetry.forEach((point) => {
      const d = distanceBetween(lat, lon, point.lat, point.lon);
      if (d < minDist) {
        minDist = d;
        closest = point;
      }
    });
    return closest;
  };
  
  const distanceBetween = (lat1, lon1, lat2, lon2) => {
    // Haversine formula
    const R = 6371e3; // Earth radius in meters
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;
    const a = Math.sin(Δφ / 2) ** 2 +
              Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  return (
    <MapProvider>
      <div style={{ padding: "0px" }}>
        
        <div style={{ 
          marginBottom: "10px", 
          // display: "flex", 
          alignItems: "center", 
          position: "absolute",
          width: "500px",
          top: "410px",
          left: "12px",
          zIndex: 1000,
          }}>
          <button
            onClick={addDashboard}
            style={{
              padding: "10px 20px",
              backgroundColor: "#13A1A8",
              color: "#fff",
              border: "2px solid #fff",
              borderRadius: "5px",
              cursor: "pointer",
              marginRight: "10px"
            }}
          >
            + Add a Run ({creditCosts.ADD_RUN}⚡)
          </button>
          <button
              onClick={() => {
                fetchRuns();
                setIsModalOpen(true);
              }}
            style={{
              padding: "10px 20px",
              backgroundColor: "#FE6C62",
              color: "#fff",
              border: "2px solid #fff",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            📂 Load Run
          </button>

          <button
  onClick={() => setVideosLinked(prev => !prev)}
  style={{
    backgroundColor: videosLinked ? "#d9534f" : "#13A1A8", // red when linked, green when unlinked
    padding: "10px 20px",
    color: "#fff",
    border: "2px solid #fff",
    borderRadius: "5px",
    cursor: "pointer",
    marginLeft: "10px"
  }}
>
  
  {videosLinked ? (
    <>
    <FaVideo size={14} title="Videos" />&nbsp;Unlinked
    <Tooltip textKey="linkVideos" className="tooltip-icon" />
      
    </>
  ) : (
    <><FaVideo size={14} title="Videos" />&nbsp;Link&nbsp;
    <Tooltip textKey="linkVideos" className="tooltip-icon" />
   </>
  )}
  
</button>

        </div>
      

        {/* Modal for listing available runs */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <div className="modal-header">
                <h3>Select a Run</h3>
                <button className="close-btn" onClick={() => setIsModalOpen(false)}>
                  ✖
                </button>
              </div>
              <input
                type="text"
                placeholder="🔍 Search runs..."
                value={searchTerm}
                onChange={handleSearch}
                className="search-input"
              />
              <table className="run-table">
                <thead>
                  <tr>
                    <th onClick={() => sortRuns("name")}>Run Name ⬍</th>
                    <th onClick={() => sortRuns("date")}>Date ⬍</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRuns.map((run) => (
                    <tr key={run.id}>
                      <td>
                        <div className="run-name-cell">
                          {run.youtubeUrl ? (
                            <img
                              src={`https://img.youtube.com/vi/${extractYouTubeVideoID(
                                run.youtubeUrl
                              )}/hqdefault.jpg`}
                              alt="Run Thumbnail"
                              className="run-thumb"
                            />
                          ) : (
                            <div className="placeholder-thumbnail">
                              <FaCamera size={40} color="#ccc" />
                            </div>
                          )}
                          <span>{run.runName || "Unnamed Run"}</span>
                        </div>
                      </td>
                      <td>{run.date}</td>
                      <td>
                        <button className="select-btn" onClick={() => loadRun(run)}>
                          Select
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Map Container */}
        <div id="map" style={{ height: "400px", width: "100%" }}></div>

      {/* SEGMENT PANEL TOP-RIGHT */}
      <div
          style={{
            position: "absolute",
            top: "60px",
            right: "30px",
            zIndex: 1000,
            width: "auto",
            backgroundColor: "transparent",
            borderRadius: "6px",
            padding: "10px",
          }}
        >
          {/* Button Row */}
          {canAddSegments && (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
              marginBottom: "10px",
            }}
          >
            {/* + Add Split Button */}
            <button
              onClick={handleAddSegment}
              style={{
                marginRight: "10px",
                padding: "7px",
                backgroundColor: "#13A1A8",
                color: "#fff",
                borderRadius: "6px",
                border: "0px",
                cursor: canAddSegments ? "pointer" : "not-allowed",
              }}
              disabled={!canAddSegments}
            >
              + Add Split
            </button>
            <button
            onClick={() =>
    handleAutoSplit({
      dashboards,
      setSegments,
      setSegmentCounter,
      setShowSegmentsPanel,
      markerRefs,
      getRandomColor,
      createOrUpdateMarker,
      recalcSegmentDurations
    })
  }
              style={{
                marginRight: "10px",
                padding: "7px",
                backgroundColor: "#13A1A8",
                color: "#fff",
                borderRadius: "6px",
                border: "0px",
                cursor: canAddSegments ? "pointer" : "not-allowed",
              }}
              disabled={!canAddSegments}
            >
              AutoSplit (beta)
            </button>

            {/* Eye Toggle Button */}
            <button
              onClick={toggleSegmentsVisibility}
              style={{
                marginRight: "10px",
                padding: "7px",
                backgroundColor: "#13A1A8",
                color: "#fff",
                borderRadius: "6px",
                border: "0px",
              }}
            >
              {showSegmentsPanel ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          )}
<div id="segmentcreator-container" style={{
  display: showSegmentsPanel ? "block" : "none",
  width: "auto",
  maxHeight: "320px",
  overflowY: "auto",
  }}>
  {segments.map((seg) => (
    <SegmentCreator
    key={seg.id}
    segment={seg}
    dashboards={dashboards}   // so we can color-code by dash
    videoRef={masterVideoRef}
    gpsData={masterTelemetry} // for the master only if needed
    onUpdateSegment={handleUpdateSegment}
    onDelete={() => handleDeleteSegment(seg.id)}
    masterTime={masterTime}
    />
  ))}
  </div>
</div>

        {/* Dashboards List */}
        <div
className="multidashboards-container"
        >
        {dashboards.length === 0 && (
        <div style={{ display: "flex", width: "100%",height: "100%", justifyContent: "center", alignItems: "center" }}>
            <img src="img/image_data.png" alt="Comparison Illustration" 
            style={{
              width: "100px",
              height: "80px",
              margin: "10px",
              opacity: "0.5",
            }
            }/>No runs loaded yet. Click "Add a Run" to get started!
          </div>
        )}
          {dashboards.map((dashboard, index) => (
            <div
              key={dashboard.id}
              className="dashboard-container"
            >
              <Dashboard
                dashboardId={dashboard.id}
                randomColor={dashboard.color}
                masterSegments={masterSegments}
                runData={dashboard.runData}
                onSegmentsChange={(seg) => {
                  if (index === 0) setMasterSegments(seg);
                }}
                isMaster={index === 0}
                onMasterVideoRefReady={handleMasterVideoRefReady}
                onMasterTelemetry={handleMasterTelemetry}
                onChildTelemetry={(newData) => handleChildTelemetry(dashboard.id, newData)}
                onMasterTimeChange={handleMasterTimeChange}
                videosLinked={videosLinked}                  // new prop
                registerVideoRef={registerVideoRef}            // new prop
                syncVideoPlay={syncVideoPlay}                  // new prop
                syncVideoPause={syncVideoPause}                // new prop
              />
              <button
                onClick={() => removeDashboard(dashboard.id)}
                style={{
                  padding: "5px 10px",
                  backgroundColor: "grey",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>
    </MapProvider>
  );
};

export default MultiDashboard;