import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCoins, FaArrowLeft, FaShoppingCart } from "react-icons/fa"; // Import icons
import Tooltip from "./Tooltip"; // Import Tooltip component
import "../App.css"; // Ensure you have styles

const BuyCredits = () => {
  const navigate = useNavigate();

  const creditOptions = [
    { amount: 150, price: 9 },
    { amount: 350, price: 19 },
    { amount: 1000, price: 49 },
  ];

  const handlePurchase = (amount, price) => {
    alert(`Proceeding to purchase ${amount} credits for $${price}`);
    navigate(`/checkout?amount=${amount}&price=${price}`);
  };

  return (
    <div className="buy-credits-container">
      <h2 className="buy-credits-title">Buy Credits <Tooltip textKey="credits"  /></h2>
      <p className="buy-credits-subtitle">Select a credit package:</p>

      <div className="credit-options">
      
        {creditOptions.map((option) => (
          <div key={option.amount} className="credit-option">
            <div className="buy-credits-details">
                <FaCoins className="credit-icon" />
            <h3>{option.amount} Credits</h3>
             </div>
            <button
              className="buy-button"
              onClick={() => handlePurchase(option.amount, option.price)}
            >${option.price}
              <FaShoppingCart className="button-icon" /> Buy Now
            </button>
          </div>
        ))}
      </div>

      <button className="back-button" onClick={() => navigate(-1)}>
        <FaArrowLeft className="button-icon" /> Back
      </button>
    </div>
  );
};

export default BuyCredits;