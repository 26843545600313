import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../App.css';

function PrivateRoute({ children }) {
  const { user } = useAuth();

  // If user is NOT logged in, redirect to /login
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // Otherwise, render whatever components were passed as children
  return children;
}

export default PrivateRoute;