// autoSplitHelper.js
export const handleAutoSplit = ({
    dashboards,
    setSegments,
    setSegmentCounter,
    setShowSegmentsPanel,
    markerRefs,
    getRandomColor,
    createOrUpdateMarker,
    recalcSegmentDurations,
  }) => {
   ///////////////////////

  if (dashboards.length === 0) return;

  const runs = dashboards.map((d) => d.runData?.telemetryData || []).filter(Boolean);
  if (runs.length === 0) return;

  const MIN_SPEED_KMH = 3;
  const MIN_DURATION_SEC = 3;
  const MIN_SECTIONS = 3;
  const SPEED_DELTA_THRESHOLD = 3; // m/s
  const MIN_SEG_DURATION = 5; // seconds

  const mainRun = runs[0];
  if (!mainRun || mainRun.length === 0) return;

  const getMovingWindowStart = (data) => {
    for (let i = 0; i < data.length - 3; i++) {
      const moving = data.slice(i, i + 3).every(p => p.speed * 3.6 > MIN_SPEED_KMH);
      if (moving) return i;
    }
    return 0;
  };

  const getMovingWindowEnd = (data) => {
    for (let i = data.length - 3; i >= 0; i--) {
      const moving = data.slice(i, i + 3).every(p => p.speed * 3.6 > MIN_SPEED_KMH);
      if (moving) return i + 2;
    }
    return data.length - 1;
  };

  const firstIdx = getMovingWindowStart(mainRun);
  const lastIdx = getMovingWindowEnd(mainRun);
  const startTime = mainRun[firstIdx].time;
  const endTime = mainRun[lastIdx].time;
  const totalTime = endTime - startTime;

  const movingData = mainRun.slice(firstIdx, lastIdx + 1);
  const splitTimes = [];

  // Priority 1: stop → restart
  for (let i = 1; i < movingData.length - 1; i++) {
    const prev = movingData[i - 1];
    const curr = movingData[i];
    const next = movingData[i + 1];
    if (prev.speed > 0.5 && curr.speed < 0.2 && next.speed > 0.5) {
      splitTimes.push(curr.time);
    }
  }

  // Priority 2: sudden speed change
  for (let i = 1; i < movingData.length; i++) {
    const delta = Math.abs(movingData[i].speed - movingData[i - 1].speed);
    if (delta > SPEED_DELTA_THRESHOLD) {
      splitTimes.push(movingData[i].time);
    }
  }

  // Fallback: evenly spaced time-based cuts
  if (splitTimes.length < MIN_SECTIONS - 1) {
    const evenStep = totalTime / MIN_SECTIONS;
    for (let i = 1; i < MIN_SECTIONS; i++) {
      splitTimes.push(startTime + evenStep * i);
    }
  }

  // Clean up and include start/end
 // Clean up and include start/end
let uniqueTimes = Array.from(new Set(splitTimes)).sort((a, b) => a - b);

// Cap the number of splits to MAX_SECTIONS
const MAX_SECTIONS = 10;
const maxSplits = MAX_SECTIONS - 1;

if (uniqueTimes.length > maxSplits) {
  const step = Math.floor(uniqueTimes.length / maxSplits);
  uniqueTimes = Array.from({ length: maxSplits }, (_, i) => uniqueTimes[i * step]);
}

const times = [startTime, ...uniqueTimes, endTime];
  const segments = [];

  for (let i = 0; i < times.length - 1; i++) {
    const tStart = times[i];
    const tEnd = times[i + 1];

    const duration = tEnd - tStart;
    if (duration < MIN_SEG_DURATION) continue;

    const pointsInWindow = mainRun.filter(p => p.time >= tStart && p.time <= tEnd);
    if (pointsInWindow.length < 2) {
      console.warn("🚨 Not enough telemetry points for segment", i + 1);
      continue;
    }

    const startPoint = pointsInWindow[0];
    const endPoint = pointsInWindow[pointsInWindow.length - 1];

    const segmentId = Date.now() + i;
    const color = getRandomColor();

    const segment = {
      id: segmentId,
      name: `S${i + 1}`,
      color,
      startFrame: startPoint.time,
      endFrame: endPoint.time,
      startGPS: { lat: startPoint.lat, lon: startPoint.lon },
      endGPS: { lat: endPoint.lat, lon: endPoint.lon },
      durations: {},
    };

    // Prepare markers
    if (!markerRefs.current[`${segmentId}-start`]) {
      markerRefs.current[`${segmentId}-start`] = { current: null };
    }
    if (!markerRefs.current[`${segmentId}-end`]) {
      markerRefs.current[`${segmentId}-end`] = { current: null };
    }

    if (window.mapInstance) {
      console.log("🧭 Marker creation input", {
        gps: segment.startGPS,
        label: `${segment.name} Start`,
        mapRef: window.mapInstance,
        markerRef: markerRefs.current[`${segmentId}-start`]
      });

      createOrUpdateMarker(
        { current: window.mapInstance },
        segment.startGPS,
        `${segment.name} Start`,
        markerRefs.current[`${segmentId}-start`],
        true
      );
      createOrUpdateMarker(
        { current: window.mapInstance },
        segment.endGPS,
        `${segment.name} End`,
        markerRefs.current[`${segmentId}-end`],
        false
      );
    }

    segments.push(segment);
  }

  setSegments(segments.map(recalcSegmentDurations));
  setSegmentCounter(prev => prev + segments.length);
  setShowSegmentsPanel(true);


   ////////////////////
  };