import React, { useEffect, useRef, useState, useContext } from 'react';
import L from 'leaflet';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisV, faTimes, faVideoCamer,faDatabase, faExpand, faMinimize } from "@fortawesome/free-solid-svg-icons";
import SegmentCreator from "./SegmentCreator";
import { MapContext } from "./MapProvider";
import { auth } from "../firebase"; // Use the same auth instance as in Header.js
import { storage } from "../firebase"; // Ensure Firebase Storage is properly initialized
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { getFirestore, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import creditCosts from "../config/creditCosts";
import Tooltip from './Tooltip';
import FitParser from 'fit-file-parser';
import { text } from '@fortawesome/fontawesome-svg-core';

const db = getFirestore();

Chart.register(zoomPlugin, annotationPlugin);
function getFormattedRunName() {
  const now = new Date();
  const mm = String(now.getMonth() + 1).padStart(2, "0");
  const dd = String(now.getDate()).padStart(2, "0");
  const yy = String(now.getFullYear()).slice(-2);
  const hh = String(now.getHours()).padStart(2, "0");
  const min = String(now.getMinutes()).padStart(2, "0");
  const sec = String(now.getSeconds()).padStart(2, "0");
  return `run_${mm}-${dd}-${yy}_${hh}-${min}-${sec}`;
}
const Dashboard = ({
  dashboardId,
  randomColor,
  // NEW props from parent:
  masterSegments,
  onSegmentsChange,
  isMaster,
    runData = null,
    onMasterVideoRefReady = () => {},
    onMasterTelemetry = () => {},
    onChildTelemetry = () => {}, 
    onMasterTimeChange = () => {}, 
    videosLinked,       // new prop
    registerVideoRef,   // new prop
    syncVideoPlay,      // new prop
    syncVideoPause,     // new prop
}) => {
  const [runName, setRunName] = useState(getFormattedRunName());
  const [credits, setCredits] = useState(0);
  const [telemetryData, setTelemetryData] = useState([]);
  const [videoSyncOffset, setVideoSyncOffset] = useState(0);
  const defaultRunData = {
    youtubeUrl: null,
    uploadedToYouTube: false,
    videoFileName: null,
    telemetryData: [],
    metrics: {},
  };
  const user = auth.currentUser;
  const userId = user ? user.uid : null; // ✅ Get the authenticated user's Firestore UID
  const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB chunks
 const [myColor] = useState(randomColor);
 const [youtubeUrl, setYoutubeUrl] = useState('');
 const youtubeIframeRef = useRef(null);
 const combinedChartRef = useRef(null);
 const [youtubePlayer, setYoutubePlayer] = useState(null);
 const [youtubePlayerReady, setYoutubePlayerReady] = useState(false);
 const videoRef = useRef(null);
 const youtubePlayerRef = useRef(null);
 const [selectedVideoFile, setSelectedVideoFile] = useState(null);
 const { mapRef } = useContext(MapContext);
 const dashboardLayers = useRef(null);
 const colorLibrary = [
   "#D62728","#1F77B4","#2CA02C","#9467BD","#8C564B","#E377C2","#7F7F7F",
   "#BCBD22","#17BECF","#FF7F0E","#C49C94","#393B79","#637939","#8C6D31",
   "#843C39","#A55194","#6B6ECF","#B5CF6B","#E6550D","#3182BD"
 ];
 const [currentSpeed, setCurrentSpeed] = useState(0);
 const [frontBrake, setFrontBrake] = useState(0);
 const [rearBrake, setRearBrake] = useState(0);
 const [combinedBrake, setCombinedBrake] = useState(0);

 const [uploadToYouTube, setUploadToYouTube] = useState(false); // New: Checkbox state
 const [isProcessing, setIsProcessing] = useState(false);
 const [message, setMessage] = useState("");
 const [uploadProgress, setUploadProgress] = useState(0);
 const [progress, setProgress] = useState(0);
 const [youtubeDirectUrl, setYoutubeDirectUrl] = useState(null);
 const CLIENT_ID = "493819678818-jpeodeohno8dq190calvns8010c3ieue.apps.googleusercontent.com";
 const CLIENT_SECRET = "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";
 const REFRESH_TOKEN = "1//067uILqCztsuhCgYIARAAGAYSNwF-L9IrSINEZz-kYrCGBqf5yWGfbRhE5bJa7wkNJWiin6KpVR1ny3SEl5zcELqFAWAuUSVcDhQ"
 const [menuOpen, setMenuOpen] = useState(false);
 const [isFullscreen, setIsFullscreen] = useState(false);
 const fullScreenContainerRef = useRef(null);
 const currentRunData = runData || null;
 const [loadedRun, setLoadedRun] = useState(null);
 const [localYoutubeUrl, setLocalYoutubeUrl] = useState(null);
 const [activeTab, setActiveTab] = useState("graph");

 useEffect(() => {
  window.videoRef = videoRef; // Expose local video element
  window.youtubePlayer = youtubePlayer; // Expose YouTube player
  window.youtubePlayerReady = youtubePlayerReady; // Expose player readiness flag
}, [videoRef, youtubePlayer, youtubePlayerReady]);

  useEffect(() => {
    // Only update loadedRun if runData is truthy (i.e. a run has been loaded)
    if (runData) {
      setLoadedRun(runData);
    }
  }, [runData]);

  useEffect(() => {
    console.log("📌 Checking videoRef in Dashboard:", videoRef.current);
}, [videoRef]);

useEffect(() => {
  if (videoRef.current) {
      console.log(`🔄 Registering videoRef for Dashboard ${dashboardId} (Video Loaded)`);
      registerVideoRef(dashboardId, videoRef, "local");
      console.log(`✅ videoRef registered for dashboard ${dashboardId}`);
  } else {
      console.warn(`⚠️ Failed to register videoRef for dashboard ${dashboardId}, retrying...`);
  }
}, [videoRef.current, dashboardId, registerVideoRef]); // ✅ Reacts to new videos
  
  useEffect(() => {
    return () => {
      // Cleanup path & marker when this Dashboard unmounts
      if (dashboardLayers.current && mapRef?.current) {
        mapRef.current.removeLayer(dashboardLayers.current.layer);
        dashboardLayers.current = null;
      }
    };
  }, []);
  
  useEffect(() => {
    console.log("10");
    if (!isMaster || !videoRef.current) return;

    setTimeout(() => {
        onMasterVideoRefReady((prevRef) => {
            return prevRef === videoRef ? prevRef : videoRef;
        });
    }, 50);
}, [isMaster, videoRef]); // ✅ Removed `onMasterVideoRefReady`


  useEffect(() => {
    console.log("11");
    if (isMaster && telemetryData.length > 0) {
      onMasterTelemetry(telemetryData);
    }
  }, [isMaster, telemetryData, onMasterTelemetry]);

  useEffect(() => {
    console.log("12");
    console.log("Telemetry Data:", telemetryData);
  }, [telemetryData]);

  useEffect(() => {
    console.log("13");
    if (videoRef.current && registerVideoRef) {
      registerVideoRef(dashboardId, videoRef, "local");
    }
}, [dashboardId, registerVideoRef]); // ✅ Removed videoRef.curren

useEffect(() => {
  console.log("14");
  const currentRunData = runData || defaultRunData;

  // Load telemetry data if available
  setTelemetryData(currentRunData.telemetryData || []);

   // Restore offset if it exists
 if (typeof currentRunData.videoSyncOffset === 'number') {
   setVideoSyncOffset(currentRunData.videoSyncOffset);
 }

  // Load metrics from Firestore entry
  if (currentRunData.metrics) {
    const metrics = currentRunData.metrics;
    const setMetricValue = (id, value) => {
      const elementId = `${id}-${dashboardId}`;
      const el = document.getElementById(elementId);
      if (el) {
        el.textContent = value || "N/A";
      } else {
        console.warn(`updateMetrics: Element with id ${elementId} not found.`);
      }
    };

    setMetricValue("total-distance", metrics.totalDistance);
    setMetricValue("total-elevation-loss", metrics.elevationLoss);
    setMetricValue("total-time-braking-front", metrics.brakingFront);
    setMetricValue("total-time-braking-rear", metrics.brakingRear);
    setMetricValue("total-time-braking", metrics.totalBraking);
    setMetricValue("proportion-power", metrics.proportionPower);
  }

  // ✅ Prompt to load a local video if no YouTube video exists
  if (!currentRunData.uploadedToYouTube && currentRunData.videoFileName) {
    setTimeout(() => {
      const userResponse = window.confirm(
        `This run was saved with a local video file: "${currentRunData.videoFileName}". Would you like to select this file now?`
      );

      if (userResponse) {
        document.getElementById(`file-input-${dashboardId}`).click();
      }
    }, 1000); // Delay to ensure UI is ready
  }
}, [runData, dashboardId]);
// useEffect(() => {
//   // if telemetryData is non-empty, tell the parent
//   onChildTelemetry(telemetryData);
// }, [telemetryData, onChildTelemetry]);

  useEffect(() => {
    console.log("15");
    if (runData?.uploadedToYouTube && runData.youtubeUrl) {
      const videoId = extractYouTubeVideoID(runData.youtubeUrl);
  
      if (videoId) {
        fetchYouTubeDirectUrl(videoId).then((url) => {
          if (url) {
            setYoutubeDirectUrl(url);
          } else {
            console.warn("⚠️ Could not fetch YouTube direct URL.");
          }
        });
      }
    }
  }, [runData]);

  const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      // Request fullscreen on our container
      fullScreenContainerRef.current
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => console.error("Failed to enter fullscreen:", err));
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };

  function getCurrentUserId() {
    const user = auth.currentUser;
    return user ? user.email : null; // Return email instead of UID
  }

  const getCurrentUserEmail = () => {
    return auth.currentUser ? auth.currentUser.email : null;
  };
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

const formatDate = (date) => {
  return date.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).replace(',', ''); // Removes unnecessary comma
};

const formatDateForPlaceholder = () => {
  const now = new Date();
  const mm = String(now.getMonth() + 1).padStart(2, "0");
  const dd = String(now.getDate()).padStart(2, "0");
  const yyyy = now.getFullYear();
  return `${mm}-${dd}-${yyyy}`;
};

const placeholderName = `run_${dashboardId}_${formatDateForPlaceholder()}`;

async function getAccessToken() {
    try {
        const response = await fetch("https://oauth2.googleapis.com/token", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
                client_id: CLIENT_ID,
                client_secret: CLIENT_SECRET,
                refresh_token: REFRESH_TOKEN,
                grant_type: "refresh_token",
            }),
        });

        const data = await response.json();
        if (data.access_token) {
            return data.access_token;
        } else {
            throw new Error(`Failed to get access token: ${JSON.stringify(data)}`);
        }
    } catch (error) {
        console.error("❌ Error getting access token:", error);
        return null;
    }
}
const hasVideo = !!(selectedVideoFile || runData.youtubeUrl);
const hasData = telemetryData.length > 0;

const handleSaveRun = async () => {
  setIsProcessing(true);
  setMessage("⏳ Saving run data...");

  const userEmail = getCurrentUserEmail();
  if (!userEmail) {
    setMessage("❌ User not authenticated. Please log in.");
    setIsProcessing(false);
    return;
  }

  const userId = auth.currentUser ? auth.currentUser.uid : null;
  if (!userId) {
    setMessage("❌ User ID not found.");
    setIsProcessing(false);
    return;
  }

  // Generate or reuse a runId
  const existingRunId = (loadedRun?.runId || loadedRun?.id) || (runData?.runId || runData?.id);
  let runId = existingRunId ? existingRunId : `${userEmail}_${Date.now()}`;

  console.log("handleSaveRun: computed runId =", runId);

  if (!selectedVideoFile && !runData?.youtubeUrl && telemetryData.length === 0) {
    setMessage("❌ No data available. Please load telemetry before saving.");
    setIsProcessing(false);
    return;
  }

  let reducedTelemetryData = telemetryData;
  if (JSON.stringify(telemetryData).length > 900000) {
    console.warn("📉 Telemetry data too large. Downsampling...");
    const step = Math.ceil(telemetryData.length / 5000);
    reducedTelemetryData = telemetryData.filter((_, index) => index % step === 0);
    console.log(`✅ Telemetry reduced from ${telemetryData.length} → ${reducedTelemetryData.length} points`);
  }

  const runDataToSave = {
    runName: runName || runData?.runName || `Run_${new Date().toISOString()}`,
    userId,
    videoFileName: selectedVideoFile ? selectedVideoFile.name : runData?.videoFileName || null,
    csvFileName: telemetryData.length > 0 ? "telemetry.csv" : null,
    telemetryData: reducedTelemetryData || [],
    youtubeUrl: runData?.youtubeUrl || null,
    uploadedToYouTube: runData?.uploadedToYouTube || false,
    timestamp: new Date(),
    videoSyncOffset,
    runId,
    ownerId: userId,
    uploadToYouTube,
  };

  try {
    const runRef = doc(db, "runs", runId);
    const docSnap = await getDoc(runRef);

    if (docSnap.exists()) {
      await updateDoc(runRef, runDataToSave);
      setMessage("✅ Run updated successfully!");
    } else {
      await setDoc(runRef, runDataToSave);
      setMessage("✅ Run saved successfully!");
    }

    setLoadedRun(runDataToSave);

    // ✅ Pass runId to YouTube Upload
    if (uploadToYouTube && selectedVideoFile) {
      console.log("📤 Uploading video to YouTube...");
      await handleUploadToYouTube(selectedVideoFile, runId);
    }
  } catch (error) {
    console.error("❌ Error saving run:", error);
    setMessage(`Error: ${error.message}`);
  } finally {
    setIsProcessing(false);
  }
};

const handleUploadToYouTube = async (file, runId) => {
  if (!runId) {
    console.error("❌ handleUploadToYouTube: No runId provided. Aborting upload.");
    setMessage("❌ Error: No run ID for YouTube upload.");
    return;
  }
  setMessage("📤 Uploading to YouTube...");
  setUploadProgress(0);

  const token = await getAccessToken();
  if (!token) {
    console.error("❌ Error: No YouTube access token.");
    setMessage("❌ YouTube authentication failed.");
    return;
  }

  const metadata = {
    snippet: {
      title: runName || "New Run",
      description: "Uploaded from Victorise.",
      tags: ["cycling", "telemetry"],
      categoryId: "22",
    },
    status: { privacyStatus: "unlisted" },
  };

  // Step 1: Initiate the resumable upload session
  const initiateResumableUpload = async () => {
    const response = await fetch(
      "https://www.googleapis.com/upload/youtube/v3/videos?uploadType=resumable&part=snippet,status",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=UTF-8",
          "X-Upload-Content-Length": file.size,
          "X-Upload-Content-Type": file.type,
        },
        body: JSON.stringify(metadata),
      }
    );

    if (!response.ok) {
      throw new Error("❌ Failed to initiate resumable upload");
    }

    return response.headers.get("location"); // The upload URL
  };

  try {
    let uploadUrl = await initiateResumableUpload();
    let offset = 0;
    const chunkSize = 10 * 1024 * 1024; // 10MB

    // Step 2: Resume from where we left off (if applicable)
    const getUploadStatus = async () => {
      const statusResponse = await fetch(uploadUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Range": `bytes */${file.size}`, // Ask where to resume
        },
      });

      if (statusResponse.status === 308) {
        const rangeHeader = statusResponse.headers.get("Range");
        if (rangeHeader) {
          const rangeMatch = rangeHeader.match(/bytes=(\d+)-(\d+)/);
          if (rangeMatch) {
            offset = parseInt(rangeMatch[2]) + 1;
            console.log(`📌 Resuming upload from byte ${offset}`);
          }
        }
      }
    };

    await getUploadStatus(); // Check if we need to resume

    // Step 3: Upload chunks
    while (offset < file.size) {
      const chunk = file.slice(offset, offset + chunkSize);
      const isLastChunk = offset + chunk.size >= file.size;

      const response = await fetch(uploadUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Length": chunk.size,
          "Content-Range": `bytes ${offset}-${offset + chunk.size - 1}/${file.size}`,
        },
        body: chunk,
      });

      if (!response.ok && response.status !== 308) {
        throw new Error(`❌ Chunk upload failed: ${await response.text()}`);
      }

      offset += chunk.size;
      const progress = Math.round((offset / file.size) * 100);
      setUploadProgress(progress);
      setMessage(`📤 Uploading to YouTube... ${progress}%`);

      // If YouTube responds with 308 (Resume Incomplete), continue uploading
      if (response.status === 308) {
        console.log(`⏳ Chunk uploaded (${progress}%), resuming...`);
      } else if (response.status === 200) {
        const result = await response.json();
        if (result.id) {
          const youtubeUrl = `https://www.youtube.com/watch?v=${result.id}`;
          console.log("✅ Video fully uploaded! YouTube URL:", youtubeUrl);
          setMessage("✅ Video uploaded successfully!");
          setYoutubeUrl(youtubeUrl);

          // ✅ Update Firestore with YouTube URL
          await updateFirestoreWithYouTubeUrl(runId, youtubeUrl);

          return;
        }
      }
    }
  } catch (error) {
    console.error("❌ Chunked YouTube upload failed:", error);
    setMessage("❌ YouTube upload failed.");
  }
};

const updateFirestoreWithYouTubeUrl = async (runId, youtubeUrl) => {
  if (!runId) {
    console.error("❌ Error: No runId provided. Firestore update skipped.");
    return;
  }

  try {
    const runRef = doc(db, "runs", runId);
    await updateDoc(runRef, {
      youtubeUrl: youtubeUrl,
      uploadedToYouTube: true, // ✅ Mark run as having a YouTube video
    });

    console.log("✅ Firestore updated with YouTube URL:", youtubeUrl);
  } catch (error) {
    console.error("❌ Error updating Firestore:", error);
  }
};
const handleSyncData = () => {
  console.log("🔧 Syncing Data...");
  // 1) Are we dealing with a local video or YouTube?
  // If local video is visible:
  if (videoRef.current && videoRef.current.style.display !== "none") {
    const currentVideoTime = videoRef.current.currentTime;
    setVideoSyncOffset(currentVideoTime);
    console.log(`🔧 Syncing Data: This video time = ${currentVideoTime}s is now telemetry time=0`);
    updateDashboardMarker(0);
    updateGraphAnnotation(0);
    return;
  }

  // 2) Otherwise, if YouTube is loaded:
  if (youtubePlayer) {
    const currentVideoTime = youtubePlayer.getCurrentTime();
    setVideoSyncOffset(currentVideoTime);
    console.log(`🔧 Syncing Data: YouTube time = ${currentVideoTime}s is now telemetry time=0`);
    updateDashboardMarker(0);
    updateGraphAnnotation(0);
    return;
  }

  console.warn("⚠️ No video player is available to sync.");
};

const handleExtractGPS = async () => {
  if (!selectedVideoFile) {
    setMessage("❌ No video selected. Please upload a video first.");
    return;
  }

  setIsProcessing(true);
  // Reset progress and clear any prior message
  setProgress(0);
  setMessage("");

  try {
    const fileSize = selectedVideoFile.size;
    const numChunks = Math.ceil(fileSize / CHUNK_SIZE);
    const chunkProgress = new Array(numChunks).fill(0);
    const chunkUploadPromises = [];

    for (let i = 0; i < numChunks; i++) {
      const start = i * CHUNK_SIZE;
      const chunk = selectedVideoFile.slice(start, start + CHUNK_SIZE);
      const chunkRef = ref(storage, `videos/chunks/${selectedVideoFile.name}-${start}`);

      const uploadPromise = new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(chunkRef, chunk);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Update this chunk's progress
            chunkProgress[i] = snapshot.bytesTransferred;
            // Calculate overall progress percentage
            const totalUploaded = chunkProgress.reduce((sum, curr) => sum + curr, 0);
            const overallProgress = Math.min(Math.round((totalUploaded / fileSize) * 100), 100);
            setProgress(overallProgress);
          },
          (error) => {
            console.error("❌ Error uploading chunk:", error);
            reject(error);
          },
          async () => {
            const chunkUrl = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(chunkUrl);
          }
        );
      });
      chunkUploadPromises.push(uploadPromise);
    }

    // Wait for all chunks to upload concurrently
    const uploadedParts = await Promise.all(chunkUploadPromises);

    // Once all chunks are uploaded, update message to show merging status
    setMessage("✅ All chunks uploaded! Merging and extracting GPS...");

    // Trigger Cloud Run processing with the array of chunk URLs
    const response = await fetch("https://gopro-processor-493819678818.us-central1.run.app/process-video", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fileName: selectedVideoFile.name,
        chunks: uploadedParts,
        userId: user.uid,
      }),
    });

    const json = await response.json();
    if (!response.ok) throw new Error(json.error || "Failed to extract GPS");

    setMessage("✅ GPS Data extracted! Loading telemetry...");

    // Retrieve and parse the CSV telemetry file from Firebase Storage
    const csvFilePath = json.csvPath;
    const csvRef = ref(storage, csvFilePath);
    const csvURL = await getDownloadURL(csvRef);
    const csvResponse = await fetch(csvURL);
    const csvText = await csvResponse.text();
    const parsedTelemetry = parseCSV(csvText);
    setTelemetryData(parsedTelemetry);

    // Cleanup: Delete all uploaded chunks in parallel
    const deletePromises = uploadedParts.map(async (chunkUrl) => {
      const chunkRef = ref(storage, chunkUrl);
      await deleteObject(chunkRef);
    });
    await Promise.all(deletePromises);

    setMessage("✅ Process completed! GPS data loaded.");
  } catch (error) {
    console.error("❌ GPS extraction failed:", error);
    setMessage("❌ GPS extraction failed. Check logs.");
  } finally {
    setIsProcessing(false);
  }
};

  // --------------- HELPER FUNCTIONS ---------------
  const getTelemetryPointForTimestamp = (timestamp, data) => {
    if (!data || data.length === 0) return null;
    return data.reduce((closestPoint, currentPoint) => {
      const closestDiff = Math.abs(closestPoint.time - timestamp);
      const currentDiff = Math.abs(currentPoint.time - timestamp);
      return currentDiff < closestDiff ? currentPoint : closestPoint;
    });
  };

  const initializeDashboardLayers = (data) => {
    if (!mapRef.current || !data || data.length === 0) return;

    // Remove old path if it exists
    if (dashboardLayers.current) {
      mapRef.current.removeLayer(dashboardLayers.current.layer);
    }

    const dashboardLayer = L.layerGroup().addTo(mapRef.current);

    // Plot the path
    const coordinates = data.map((p) => [p.lat, p.lon]);
    const path = L.polyline(coordinates, { color: myColor }).addTo(dashboardLayer);

    // Create the "current position" marker
    const marker = L.circleMarker(coordinates[0], {
      radius: 6,
      color: myColor,
      fillOpacity: 0.9,
    }).addTo(dashboardLayer);

    dashboardLayers.current = { layer: dashboardLayer, marker, path };

    // Zoom map to this path
    mapRef.current.fitBounds(path.getBounds());
  };

  /**
   * Move the "current position" marker to match the video’s time
   */
  function updateDashboardMarker(telemetryTime, masterCoordinate) {
    if (!dashboardLayers.current) return;
    // If a master coordinate is provided, use it.
    if (masterCoordinate && masterCoordinate.lat && masterCoordinate.lon) {
      dashboardLayers.current.marker.setLatLng([masterCoordinate.lat, masterCoordinate.lon]);
    } else if (telemetryData && telemetryData.length > 0) {
      const point = getTelemetryPointForTimestamp(telemetryTime, telemetryData);
      if (point && dashboardLayers.current.marker) {
        dashboardLayers.current.marker.setLatLng([point.lat, point.lon]);
      }
    }
  }


  const updateGraphAnnotation = (telemetryTime) => {
    if (!combinedChartRef.current) return;
    const chart = combinedChartRef.current;
    
    // Update the annotation to the current telemetry time.
    const annotation = chart.options.plugins.annotation.annotations.videoTime;
    annotation.xMin = telemetryTime;
    annotation.xMax = telemetryTime;
    
    // Get the x-axis scale. Adjust the scale id if needed.
    const xScale = chart.options.scales.x;
    
    // Calculate current visible window width.
    const windowWidth = xScale.max - xScale.min;
    
    // We want the annotation to be at 95% of the chart width from the left.
    const desiredPosition = xScale.min + 0.95 * windowWidth;
    
    // If the new telemetry time is beyond this desired position, pan the chart.
    if (telemetryTime > desiredPosition) {
      const shift = telemetryTime - desiredPosition;
      xScale.min += shift;
      xScale.max += shift;
    }
    
    chart.update('none');
  };

  const handleGraphClick = (event, elements) => {
    if (elements.length > 0) {
        const index = elements[0].index;
        const selectedTime = combinedChartRef.current.data.labels[index];
        onMasterTimeChange(selectedTime)
        console.log(`📊 Graph clicked! Seeking to ${selectedTime}s (Dashboard: ${dashboardId})`);

           // 2) If no video loaded, do local marker + annotation update
   if (!hasVideo) {
     updateDashboardMarker(selectedTime);
     updateGraphAnnotation(selectedTime);
   } else {
        const seekFunction = window[`seekToTime_${dashboardId}`];
        if (typeof seekFunction === "function") {
            seekFunction(selectedTime);
        } else {
            console.error(`❌ seekToTime function not available for dashboard ${dashboardId}`);
        }
    }
  }
};

   /**
   * Seek function for local or YouTube video
   */
   const seekToTime = (telemetryTime) => {
    const targetVideoTime = telemetryTime + videoSyncOffset;
  
    // 🔥 Check for local video
    if (videoRef.current && videoRef.current.style.display !== "none") {
      console.log(`⏩ Seeking Local Video to ${targetVideoTime}s`);
      
      videoRef.current.currentTime = targetVideoTime;
      videoRef.current.play().then(() => {
        setTimeout(() => videoRef.current.pause(), 50); // pause shortly after
      });
      updateDashboardMarker(telemetryTime);
      updateGraphAnnotation(telemetryTime);
      return;
    }
  
    // 🔥 Ensure we have a valid YouTube player reference
    if (!youtubePlayer || !youtubePlayerReady) {
      console.warn("⚠️ YouTube player is not initialized yet. Retrying...");
      setTimeout(() => seekToTime(telemetryTime), 500);
      return;
    }
  
    console.log(`🎬 Seeking YouTube video (Dashboard: ${dashboardId}) to ${targetVideoTime}s...`);
    youtubePlayer.seekTo(targetVideoTime, true);
  
    updateDashboardMarker(telemetryTime);
    updateGraphAnnotation(telemetryTime);
  };

  const handleTimeUpdate = () => {
    if (!videoRef.current) return; // Safety check
      if (videoRef.current && !videoRef.current.paused) {
          const rawVideoTime = videoRef.current.currentTime; 
          const telemetryTime = rawVideoTime - videoSyncOffset;
          //  console.log(`📍 Local Video Time Update: ${rawVideoTime}s`);
          updateDashboardMarker(telemetryTime); // ✅ Make sure the marker updates
          updateGraphAnnotation(telemetryTime);
      }
  };

   const handleSeeked = () => {
       if (videoRef.current) {
         const rawVideoTime = videoRef.current.currentTime;
         const telemetryTime = rawVideoTime - videoSyncOffset;
         console.log(`⏩ Local Video Seeked to ${rawVideoTime}s`);
         updateDashboardMarker(telemetryTime);
         updateGraphAnnotation(telemetryTime);
       }
     };

     const handlePlayPause = () => {
      if (!videoRef.current) return;
    
      if (videoRef.current.paused) {
        videoRef.current.play();
        syncVideoPlay(dashboardId); // ✅ Sync all videos when playing
      } else {
        videoRef.current.pause();
        syncVideoPause(dashboardId); // ✅ Sync all videos when pausing
      }
    };

    const handleYouTubeStateChange = (event) => {
      if (!videosLinked) return; // ✅ Only sync when linked
    
      if (event.data === window.YT.PlayerState.PLAYING) {
        syncVideoPlay(dashboardId);
      } else if (event.data === window.YT.PlayerState.PAUSED) {
        syncVideoPause(dashboardId);
      }
    };
  
  // --------------- EFFECTS ---------------
  useEffect(() => {
    console.log("16");
    if (!videoRef.current) return;
    const handleTimeUpdate = () => {
      // The local video’s currentTime
      const t = videoRef.current.currentTime;
      onMasterTimeChange(t);
    };
    videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [onMasterTimeChange]);

  useEffect(() => {
    console.log("17");
    if (runData && runData.youtubeUrl) {
      setLocalYoutubeUrl(runData.youtubeUrl);
    }
  }, [runData]);

  useEffect(() => {
    console.log("18");
    if (message && !isProcessing) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 5000); // clear message 10 seconds after processing finishes
      return () => clearTimeout(timer);
    }
  }, [message, isProcessing]);

  useEffect(() => {
    console.log("19");
    if (!selectedVideoFile || !videoRef.current) return;
    const url = URL.createObjectURL(selectedVideoFile);
    console.log("🎬 Setting local video src:", url);
    videoRef.current.src = url;
    videoRef.current.load(); // ensures the first frame loads
  
    // Cleanup the object URL to free memory
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [selectedVideoFile]);

  useEffect(() => {
    console.log("20");
    if (runData && runData.runName) {
      setRunName(runData.runName);
    }
  }, [runData]);

  useEffect(() => {
    console.log("21");
    if (!videoRef.current) return;
  
    videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
    videoRef.current.addEventListener("seeked", handleSeeked);
  
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        videoRef.current.removeEventListener("seeked", handleSeeked);
      }
    };
  }, [videoRef, handleTimeUpdate, handleSeeked]);

// Effect to initialize YouTube Player
useEffect(() => {
  console.log("22");
  if (!window.YT) {
    console.log("🚀 Injecting YouTube IFrame API script...");
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    tag.async = true;
    document.body.appendChild(tag);

    window.onYouTubeIframeAPIReady = () => {
      console.log("🎬 YouTube API is ready!");
      setYoutubePlayerReady(true);
    };
  } else {
    console.log("✅ YouTube API already loaded.");
    setYoutubePlayerReady(true);
  }
}, []);

useEffect(() => {
  console.log("23");
  if (!youtubePlayerReady || !runData?.youtubeUrl) {
    console.log("⚠️ YouTube API is not ready yet. Skipping initialization...");
    return;
  }

  console.log(`🎬 Initializing YouTube Player (Dashboard: ${dashboardId})...`);

  const videoId = extractYouTubeVideoID(runData.youtubeUrl);
  if (!videoId) {
    console.warn("⚠️ Invalid YouTube URL, cannot initialize player.");
    return;
  }

  // Delay to ensure API is loaded properly
  setTimeout(() => {
    const player = new window.YT.Player(`youtube-object-${dashboardId}`, {
      videoId: videoId,
      playerVars: {
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
        controls: 1,
        enablejsapi: 1,
      },
      events: {
        onReady: (event) => {
          console.log(`✅ YouTube Player initialized (Dashboard: ${dashboardId}).`);
          setYoutubePlayer(event.target);
          setYoutubePlayerReady(true);

          // Register the YouTube player
          if (registerVideoRef) {
            registerVideoRef(dashboardId, event.target, "youtube");
          }
        },
      },
    });
    console.log(`🎥 YouTube Player Created (Dashboard: ${dashboardId}):`, player);
  }, 1000); // Delay ensures API readiness
}, [youtubePlayerReady, runData?.youtubeUrl]);

// Effect to listen for time updates (Local Video)
useEffect(() => {
  console.log("24");
  if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
         // Fires immediately when user finishes seeking
         videoRef.current.addEventListener('seeked', handleSeeked);
    }

  return () => {
      if (videoRef.current) {
          videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
          videoRef.current.removeEventListener('seeked', handleSeeked);
      }
  };
}, [videoRef.current, telemetryData,videoSyncOffset]);

// Effect to poll YouTube time and update marker
useEffect(() => {
  console.log("25");
  if (!youtubePlayerReady || !window.YT || !runData.youtubeUrl) {
    console.log("⚠️ YouTube API is not ready yet. Skipping initialization...");
    return;
  }

  console.log("🎬 Initializing YouTube Player...");

  const videoId = extractYouTubeVideoID(runData.youtubeUrl);
  if (!videoId) {
    console.warn("⚠️ Invalid YouTube URL, cannot initialize player.");
    return;
  }

  setTimeout(() => {
    const player = new window.YT.Player(`youtube-object-${dashboardId}`, {
      videoId: videoId,
      playerVars: {
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
        controls: 1,
        enablejsapi: 1,
      },
      events: {
        onReady: (event) => {
          console.log("✅ YouTube Player initialized.");
          setYoutubePlayer(event.target);
          window.youtubePlayer = event.target; // 🔥 Make it globally accessible
          setYoutubePlayerReady(true);
        },
        onStateChange: (event) => {
          if (event.data === window.YT.PlayerState.PLAYING) {
            console.log("▶️ YouTube Video is Playing.");
          }
        },
      },
    });

    console.log("🎥 YouTube Player Created:", player);
  }, 1000); // Give it some buffer time to ensure API is fully loaded
}, [youtubePlayerReady, runData.youtubeUrl]);

useEffect(() => {
  // Attach functions to `window` for manual debugging
  window.updateGraphAnnotation = updateGraphAnnotation;
  window.updateDashboardMarker = updateDashboardMarker;
}, []);
useEffect(() => {
  console.log("26");
  if (!youtubePlayerReady || !youtubePlayer || !combinedChartRef.current) return;

  let lastTime = -1;
  const interval = setInterval(() => {
    if (youtubePlayer && typeof youtubePlayer.getCurrentTime === "function") {
      const rawVideoTime = youtubePlayer.getCurrentTime();
      if (!isNaN(rawVideoTime) && rawVideoTime !== undefined) {
        const telemetryTime = rawVideoTime - videoSyncOffset;

        if (Math.abs(telemetryTime - lastTime) > 0.01) {
          updateDashboardMarker(telemetryTime);
          updateGraphAnnotation(telemetryTime);
          lastTime = telemetryTime;
        }
      }
    }
  }, 500);

  return () => clearInterval(interval);
}, [youtubePlayerReady, youtubePlayer, videoSyncOffset]);

  /**
   * 4) Whenever we get telemetry data, initialize the path + chart + metrics
   */
  useEffect(() => {
    console.log("27");
    if (telemetryData.length > 0) {
        console.log("📍 Initializing Dashboard Layers...");
        initializeDashboardLayers(telemetryData);
        initializeCombinedChart(telemetryData);
        updateMetrics(telemetryData);
    } else {
        console.warn("⚠️ No telemetry data. Removing existing paths.");
        if (dashboardLayers.current) {
            mapRef.current.removeLayer(dashboardLayers.current.layer);
            dashboardLayers.current = null;
        }
    }
}, [telemetryData]);

  /**
   * 5) On map click, find the nearest lat/lng in this dashboard’s path
   *    and seek the video (local or YouTube) to that time
   */
  useEffect(() => {
    console.log("28");
    const handleMapClick = (e) => {
      if (!telemetryData || telemetryData.length === 0) return;
  
      const { lat, lng } = e.latlng;
    
      let closestPoint = null;
      let minDist = Infinity;
      telemetryData.forEach((p) => {
        const dist = calculateDistance(lat, lng, p.lat, p.lon);
        if (dist < minDist) {
          minDist = dist;
          closestPoint = p;
        }
      });
  
      if (closestPoint) {
        console.log(`📍 Map Clicked! Seeking to time: ${closestPoint.time}s`);
        onMasterTimeChange(closestPoint.time);

           if (!hasVideo) {
                // move the marker & annotation right now
               updateDashboardMarker(closestPoint.time);
                updateGraphAnnotation(closestPoint.time);
              } else {
                // otherwise, if we do have a video, we do the usual seek function
        const seekFunction = window[`seekToTime_${dashboardId}`] || window.seekToTime;
        if (typeof seekFunction === "function") {
          seekFunction(closestPoint.time);
        } else {
          console.error("❌ seekToTime function is not available!");
        }
      }
      }
    };
  
    if (mapRef.current) {
      mapRef.current.on("click", handleMapClick);
    }
  
    return () => {
      if (mapRef.current) {
        mapRef.current.off("click", handleMapClick);
      }
    };
  }, [telemetryData, mapRef]);

  
  useEffect(() => {
    console.log("29");
    if (activeTab === "graph" && telemetryData.length > 0) {
      setTimeout(() => {
        initializeCombinedChart(telemetryData);
      }, 100);
    }
  }, [activeTab, telemetryData, isFullscreen, dashboardId]);
  
  useEffect(() => {
    console.log("30");
    if (activeTab === "graph" && telemetryData.length > 0) {
      setTimeout(() => {
        initializeCombinedChart(telemetryData);
      }, 100);
    }
  }, [activeTab, telemetryData, isFullscreen, dashboardId]);

  // --------------- HANDLERS ---------------
  useEffect(() => {
    console.log("31");
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    console.log("32");
    if (typeof seekToTime !== "function") {
        console.warn("⚠️ seekToTime is not yet defined. Skipping registration.");
        return;
    }
    window[`seekToTime_${dashboardId}`] = seekToTime;
    // console.log(`✅ seekToTime() now available for dashboard ${dashboardId}`);
}, [dashboardId, seekToTime]); // ✅ Ensure useEffect runs when `seekToTime` changes

  /**
   *  Drag/drop: if video => load videoRef, if CSV => parse and setTelemetryData
   */
  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add('dragover');
  };
  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove('dragover');
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove("dragover");
    const file = e.dataTransfer.files[0];
    if (!file) return;
  
    if (file.type.startsWith("video/")) {
      // Set the file in state
      setSelectedVideoFile(file);
      return;
    }
  
    if (file.name.endsWith(".csv")) {
      handleTelemetryFile({ target: { files: [file] } });
    } else if (file.name.endsWith(".gpx")) {
      handleGpxFile(file);
    } else if (file.name.endsWith(".fit")) {
      handleFitFile(file);
    }
  };

  const handleTelemetryFile = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const parsedData = parseCSV(text);
      setTelemetryData(parsedData);
      onChildTelemetry(parsedData);
    };
    reader.readAsText(file);
  };

  function handleGpxFile(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const gpxText = e.target.result;
      const parsed = parseGPX(gpxText);
      setTelemetryData(parsed); // same place you set CSV data
      onChildTelemetry(parsed);
    };
    reader.readAsText(file);
  }
  
  function parseGPX(gpxText) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(gpxText, "application/xml");
  
    // Gather all <trkpt> elements
    const trkpts = [...xmlDoc.getElementsByTagName("trkpt")];
  
    const results = trkpts.map((trkpt) => {
      const lat = parseFloat(trkpt.getAttribute("lat"));
      const lon = parseFloat(trkpt.getAttribute("lon"));
  
      // Elevation:
      const eleTag = trkpt.getElementsByTagName("ele")[0];
      const elevation = eleTag ? parseFloat(eleTag.textContent) : 0;
  
      // Time (convert to seconds from start if you like, or keep as a Date):
      const timeTag = trkpt.getElementsByTagName("time")[0];
      const timeString = timeTag ? timeTag.textContent : null;
      const timeMs = timeString ? Date.parse(timeString) : null;
  
      // For demonstration, let’s store the actual epoch seconds:
      // We can post-process it to "seconds from the start" if needed
      let timeSec = null;
      if (timeMs) {
        timeSec = timeMs / 1000.0; // epoch seconds
      }
  
      // Heart rate in <gpxtpx:hr>:
      const hrTag = trkpt.getElementsByTagName("gpxtpx:hr")[0];
      const heartRate = hrTag ? parseFloat(hrTag.textContent) : null;
  
      // If speed is provided in an extension, parse it similarly. 
      // Otherwise, you can compute speed from distance/time differences:
      // This example doesn’t show <speed> from GPX by default. 
      // Some tools put them in <extensions>. 
      // For now, we'll set speed to 0, then compute it later if you want
      const speed = 0;
  
      // Possibly parse power if your GPX exporter includes it:
      let power = 0;
      const extTag = trkpt.getElementsByTagName("extensions")[0];
      if (extTag) {
        const powerTag = extTag.getElementsByTagName("power")[0];
        if (powerTag) {
          power = parseFloat(powerTag.textContent) || 0; // now valid
        }
      }
      
      return {
        lat,
        lon,
        elevation,
        time: timeSec,    // or time in seconds from start
        speed,            // might be 0 if not provided in GPX
        heartRate,        // extra field
        power,            // extra field
      };
    });
  
    // Optionally you may want to shift all times so that the earliest trackpoint = time 0
    if (results.length > 0 && results[0].time) {
      const startTime = results[0].time;
      results.forEach((r) => {
        if (r.time) {
          r.time = r.time - startTime; // so first data point is time=0
        }
      });
    }
    for (let i = 1; i < results.length; i++) {
      const prev = results[i - 1];
      const curr = results[i];
      const distMeters = calculateDistance(prev.lat, prev.lon, curr.lat, curr.lon);
      const dt = curr.time - prev.time; // in seconds
      
      // Convert m/s to km/h => multiply by 3.6
      if (dt > 0) {
        const speedKmh = (distMeters / dt) * 3.6;
        curr.speed = speedKmh;
      }
    }
    return results;
  }

  function handleFitFile(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      parseFit(arrayBuffer);
    };
    reader.readAsArrayBuffer(file);
  }
  
  function parseFit(arrayBuffer) {
    const fitParser = new FitParser({
      force: true,
      speedUnit: "km/h",
      lengthUnit: "km",
      temperatureUnit: "celcius",
      elapsedRecordField: true,
    });
    fitParser.parse(arrayBuffer, (error, data) => {
      if (error) {
        console.error("FIT parse error:", error);
        return;
      }
  
      console.log("FIT data:", data);
      if (!data.records) {
        console.error("No records found in FIT file.");
        return;
      }
  
      const startTs = new Date(data.records[0].timestamp).getTime() / 1000;
  
      const result = data.records
        // skip records with no lat/lon if needed
        .filter(r => r.position_lat !== undefined && r.position_long !== undefined)
        .map(r => {
          const t = new Date(r.timestamp).getTime() / 1000; // epoch seconds
          return {
            time: t - startTs,                 // time=0 at first record
            lat: r.position_lat,
            lon: r.position_long,
            speed: r.speed,
            heartRate: r.heart_rate,
            power: r.power,
            elevation: r.altitude,
          };
        });
  
      result.sort((a, b) => a.time - b.time);
      setTelemetryData(result);
    });
  }

  /**
   * If you want to load YouTube from user input
   */
  const loadYoutubeVideo = () => {
    const videoId = extractYouTubeVideoID(youtubeUrl);
    if (videoId && youtubePlayerReady) {
      youtubePlayer.loadVideoById(videoId);
    } else {
      alert('Invalid YouTube URL. Please try again.');
    }
  };
  const extractYouTubeVideoID = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const fetchYouTubeDirectUrl = async (videoId) => {
    try {
      const API_KEY = "AIzaSyBFyx7VqIMzBWvILQfYIL84syCi8CHtZNE";
      const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=contentDetails`;
  
      const response = await fetch(apiUrl);
      const data = await response.json();
  
      if (data.items.length > 0) {
        // YouTube does not provide direct URLs; alternative methods needed.
        return `https://www.youtube.com/get_video_info?video_id=${videoId}`;
      } else {
        console.error("YouTube video data not found.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching YouTube video data:", error);
      return null;
    }
  };

  // --------------- CSV and Chart ---------------
// Updated parseCSV function to support BYB file format
const parseCSV = (text) => {
  // Detect delimiter: use semicolon if there are more semicolons than commas.
  let delimiter = ",";
  const commaCount = text.split(",").length;
  const semicolonCount = text.split(";").length;
  if (semicolonCount > commaCount) {
    delimiter = ";";
  }

  const lines = text.trim().split("\n");
  if (lines.length < 2) return [];

  // Split the header line.
  const headers = lines[0].split(delimiter).map(h => h.trim());
  
  // Determine file type.
  // BYB file headers: Time, Fork, Shock, Fbrake, Rbrake, Speed, AccX, AccY, AccZ, GyroX, GyroY, GyroZ, Latitude, Longitude, Altitude, gpsSpeed
  const isBYB = headers.includes("Fork") && headers.includes("Shock") &&
                headers.includes("Fbrake") && headers.includes("Rbrake");

  // Setup header mapping.
  let headerMap = {};
  if (isBYB) {
    headerMap = {
      "Time": "time",
      "Fork": "fork",
      "Shock": "shock",
      "Fbrake": "front_brake",
      "Rbrake": "rear_brake",
      "Speed": "speed",
      "AccX": "accel_x",
      "AccY": "accel_y",
      "AccZ": "accel_z",
      "GyroX": "gyro_x",
      "GyroY": "gyro_y",
      "GyroZ": "gyro_z",
      "Latitude": "lat",
      "Longitude": "lon",
      "Altitude": "elevation",
      "gpsSpeed": "gps_speed"
    };
  } else {
    headerMap = {
      "Time (s)": "time",
      "Distance (m)": "distance",
      "Latitude (deg)": "lat",
      "Longitude (deg)": "lon",
      "Elevation (m)": "elevation",
      "Speed (km/h)": "speed",
      "Front Brake Power (W)": "front_brake",
      "Rear Brake Power (W)": "rear_brake"
    };
  }

  // Build a mapping of our field keys to column indices.
  const colMap = {};
  headers.forEach((header, index) => {
    if (headerMap[header]) {
      colMap[headerMap[header]] = index;
    }
  });

  // Limit processing to at most 10,000 rows (excluding header).
  const totalDataLines = lines.length - 1;
  const step = totalDataLines > 10000 ? Math.ceil(totalDataLines / 10000) : 1;

  const data = [];
  for (let i = 1; i < lines.length; i += step) {
    const values = lines[i].split(delimiter);
    if (values.length < headers.length) continue;
    const row = {};
    for (const [key, idx] of Object.entries(colMap)) {
      const v = values[idx].trim();
      const num = parseFloat(v);
      row[key] = isNaN(num) ? v : num;
    }
    // Include rows with basic telemetry data.
    if (row.time !== undefined && row.lat !== undefined && row.lon !== undefined && row.speed !== undefined) {
      data.push(row);
    }
  }
  
  // For BYB files, normalize fork and shock values.
  if (isBYB && data.length > 0) {
    const forkValues = data.map(r => r.fork).filter(v => typeof v === "number");
    const shockValues = data.map(r => r.shock).filter(v => typeof v === "number");

    if (forkValues.length > 0) {
      const minFork = forkValues.reduce((min, v) => (v < min ? v : min), Infinity);
      const maxFork = forkValues.reduce((max, v) => (v > max ? v : max), -Infinity);
      data.forEach(r => {
        if (typeof r.fork === "number" && maxFork !== minFork) {
          r.fork_normalized = (r.fork - minFork) / (maxFork - minFork);
        }
      });
    }
    if (shockValues.length > 0) {
      const minShock = shockValues.reduce((min, v) => (v < min ? v : min), Infinity);
      const maxShock = shockValues.reduce((max, v) => (v > max ? v : max), -Infinity);
      data.forEach(r => {
        if (typeof r.shock === "number" && maxShock !== minShock) {
          r.shock_normalized = (r.shock - minShock) / (maxShock - minShock);
        }
      });
    }
  }
  
  // Sort data by time.
  data.sort((a, b) => a.time - b.time);
  return data;
};

  const initializeCombinedChart = (data) => {
    const canvasEl = document.getElementById(`combinedGraph-${dashboardId}`);
    if (!canvasEl) {
      console.warn("initializeCombinedChart: Canvas element not found; skipping initialization.");
      return;
    }
    
    const ctx = canvasEl.getContext('2d');
    
    // Destroy previous chart instance if exists
    if (combinedChartRef.current) {
      combinedChartRef.current.destroy();
    }

    const timeData = data.map(d => d.time);
// Compute raw speed values in km/h:
const rawSpeedData = data.map(d => d.speed * 3.6);
// Compute the maximum speed (avoid zero to prevent division by zero):
const maxSpeed = Math.max(...rawSpeedData, 1);
// Normalize speed data to a range (here 0–1000):
const normalizedSpeedData = rawSpeedData.map(s => (s / maxSpeed) * 1000);

    const frontBrakeData = data.map(d => d.front_brake);
    const rearBrakeData = data.map(d => d.rear_brake);
    const heartRateData = data.map(d => d.heartRate || 0);
    const powerData = data.map(d => d.power*20 || 0);
    const forkData = data.map(d => (typeof d.fork_normalized === 'number' ? d.fork_normalized * 100 : null));
    const shockData = data.map(d => (typeof d.shock_normalized === 'number' ? d.shock_normalized * 100 : null));
   
    // const ctx = document.getElementById(`combinedGraph-${dashboardId}`).getContext('2d');
    combinedChartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: timeData,
        datasets: [
          { label: 'Speed (km/h)', data: normalizedSpeedData, borderColor: '#d9534f', fill: true,pointStyle: 'line',borderWidth: 3  },
          { label: 'Front Brake Power (W)', data: frontBrakeData, borderColor: '#b8d419', fill: false,pointStyle: 'line',borderWidth: 3 },
          { label: 'Rear Brake Power (W)', data: rearBrakeData, borderColor: '#1385a8', fill: false,pointStyle: 'line',borderWidth: 3  },
          {
            label: 'Heart Rate (bpm)',
            data: heartRateData,
            borderColor: '#d777a6',
            fill: false,
            pointStyle: 'line',
            borderWidth: 3 
          },
          {
            label: 'Power (W)',
            data: powerData,
            borderColor: 'orange',
            fill: false,
            pointStyle: 'line',
            borderWidth: 3 
          },
          { label: 'Fork Position (%)', data: forkData, borderColor: '#007bff', fill: false, pointStyle: 'line', borderWidth: 3 },
          { label: 'Shock Position (%)', data: shockData, borderColor: '#28a745', fill: false, pointStyle: 'line', borderWidth: 3 },
      
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'linear',
            ticks: { maxTicksLimit: 20, display: false, color: '#aaa'  },
            title: { display: true, text: 'Time (s)' , color: '#aaa' },
            grid: { color: '#444'},
          },
          y: {
            ticks: { maxTicksLimit: 20, display: false , color: '#aaa' },
            title: { display: true, text: 'Value' , color: '#aaa'},
            grid: { color: '#2a2a2a'},
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
              color: '#aaa'
            }
          },
          zoom: {
            pan: { enabled: true, mode: 'x' },
            zoom: {
              wheel: { enabled: true },
              pinch: { enabled: true },
              mode: 'x',
            },
          },
          annotation: {
            annotations: {
              videoTime: {
                type: 'line',
                xMin: 0,
                xMax: 0,
                borderColor: 'white',
                borderWidth: 2,
                label: { content: '', enabled: true, position: 'top' },
              },
            },
          },
          tooltip: {
            callbacks: {
              title: (tooltipItems) => {
                if (tooltipItems && tooltipItems.length > 0) {
                  return "Timestamp: " + tooltipItems[0].label + " sec";
                }
                return "";
              },
              label: (context) => {
                const scaledValue = context.parsed.y;
                if (context.dataset.label === 'Speed (km/h)') {
                  // Convert normalized value back to original raw speed:
                  const originalSpeed = (scaledValue / 1000) * maxSpeed;
                  return `Speed: ${originalSpeed.toFixed(1)} km/h`;
                } else if (context.dataset.label === 'Power (W)') {
                  const originalPower = scaledValue / 20;
                  return `Power: ${originalPower.toFixed(1)} W`;
                }
                return `${context.dataset.label}: ${scaledValue.toFixed(1)}`;
              },
            },
          },
        },
        onClick: handleGraphClick,
      },
    });

  };

 

  /**
   * Update textual metrics
   */
  const updateMetrics = (data) => {
    if (!data.length) return;
  
    const totalDistance =
      data[data.length - 1].totalDistance ||
      data.reduce((sum, point, i) => {
        if (i === 0) return 0;
        return sum + calculateDistance(
          data[i - 1].lat, data[i - 1].lon,
          point.lat, point.lon
        );
      }, 0);
  
    const totalElevationLoss = data.reduce((acc, point, i) => {
      if (i > 0 && point.elevation && data[i - 1].elevation) {
        const diff = data[i - 1].elevation - point.elevation;
        return diff > 0 ? acc + diff : acc;
      }
      return acc;
    }, 0);
  
    // Calculate braking metrics...
    const totalTimeBrakingFront = data.filter(p => p.front_brake > 0).length;
    const totalTimeBrakingRear = data.filter(p => p.rear_brake > 0).length;
    const totalTimeBraking = totalTimeBrakingFront + totalTimeBrakingRear;
    const totalFrontPower = data.reduce((sum, p) => sum + (p.front_brake || 0), 0);
    const totalRearPower = data.reduce((sum, p) => sum + (p.rear_brake || 0), 0);
    const totalPower = totalFrontPower + totalRearPower;
    const proportionFront = totalPower > 0 ? ((totalFrontPower / totalPower) * 100).toFixed(1) : 0;
    const proportionRear = totalPower > 0 ? ((totalRearPower / totalPower) * 100).toFixed(1) : 0;
    
    // Helper function: safely update an element's text content
    const safeSetTextContent = (id, text) => {
      const el = document.getElementById(`${id}-${dashboardId}`);
      if (el) {
        el.textContent = text;
      } else {
        console.warn(`Element with id ${id}-${dashboardId} not found.`);
      }
    };
  
    safeSetTextContent("total-distance", (totalDistance / 1000).toFixed(2));
    safeSetTextContent("total-elevation-loss", totalElevationLoss.toFixed(2));
    safeSetTextContent("total-time-braking-front", totalTimeBrakingFront);
    safeSetTextContent("total-time-braking-rear", totalTimeBrakingRear);
    safeSetTextContent("total-time-braking", totalTimeBraking);
    safeSetTextContent("proportion-power", `${proportionFront}% / ${proportionRear}%`);
    
    // Optionally update max braking force metrics if their elements exist:
    const maxFrontPower = Math.max(...data.map(d => d.front_brake || 0));
    const maxRearPower = Math.max(...data.map(d => d.rear_brake || 0));
    const maxBrakingForceVal = Math.max(maxFrontPower, maxRearPower);
    const maxTypeEl = document.getElementById(`max-braking-type-${dashboardId}`);
    const maxForceEl = document.getElementById(`max-braking-force-${dashboardId}`);
    if (maxForceEl) {
      maxForceEl.textContent = maxBrakingForceVal.toFixed(1);
    }
    if (maxTypeEl) {
      let maxType = "";
      if (maxBrakingForceVal <= 0) {
        maxType = "None";
      } else if (maxFrontPower > maxRearPower) {
        maxType = "Front";
      } else if (maxRearPower > maxFrontPower) {
        maxType = "Rear";
      } else {
        maxType = "Equal";
      }
      maxTypeEl.textContent = maxType;
    }
  };

  /**
   * Calculate great-circle distance between lat/lng
   */
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3;
    const phi1 = (lat1 * Math.PI) / 180;
    const phi2 = (lat2 * Math.PI) / 180;
    const deltaPhi = ((lat2 - lat1) * Math.PI) / 180;
    const deltaLambda = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(deltaPhi / 2) ** 2 +
      Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

   /**
   * Finds the nearest point in THIS telemetry array to (targetLat, targetLon)
   */
   const findNearestTelemetryPoint = (targetLat, targetLon) => {
    if (!telemetryData || telemetryData.length === 0) return null;
    let closest = null;
    let minDist = Infinity;
    for (let i = 0; i < telemetryData.length; i++) {
      const p = telemetryData[i];
      const dist = calculateDistance(targetLat, targetLon, p.lat, p.lon);
      if (dist < minDist) {
        minDist = dist;
        closest = p;
      }
    }
    return closest;
  };

  const unloadVideo = () => {
    // Unload local video if any.
    if (videoRef.current) {
      videoRef.current.src = "";
      videoRef.current.load();
      videoRef.current.style.display = "none";
    }
    setSelectedVideoFile(null);
  
    // Hide YouTube if applicable:
    if (youtubeIframeRef.current) {
      youtubeIframeRef.current.style.display = "none";
    }
    if (youtubePlayer) {
      youtubePlayer.stopVideo();
    }
    // Clear the local YouTube URL so that the YouTube video element is removed.
    setLocalYoutubeUrl(null);
  };
  
  const unloadData = () => {
    setTelemetryData([]);
  };

  // -------------------------------------------------------------
  // The rest of your Dashboard code: video drop, marker, chart, etc.
  // (omitted here for brevity — your existing code should remain)
  // -------------------------------------------------------------

 
  return (
    
    <div className="super-dashboard-container">
      <div className="dashboard-container"
       style={{
        display: "BLOCK",
        padding: "0px",
        border: `2px solid`,
        borderColor: myColor,
        borderRadius: "8px",
        backgroundColor: "#222",
        margin: "0px 0px",
        maxWidth: "800px",
        }}>


        <div className="left-column">
        {(selectedVideoFile || telemetryData.length > 0) && (
        <div id="save-run" className="save-run">
  {/* Styled Input - Default Name Format */}
  <div style={{ position: "relative", display: "inline-block" }}>
  <div
      style={{
        cursor: "pointer",
        marginRight: "5px",
      }}
      onClick={toggleMenu}
    >
      {/* You can use an actual icon library or just text. Example: */}
      &#8942; {/* HTML entity for vertical ellipsis */}
    </div>
   

  {/* The "dropdown" menu, absolutely positioned, only visible if menuOpen=true */}
  {menuOpen && (
    <div
      style={{
        position: "absolute",
        top: "30px", // a bit below the icon
        left: "0px", // or align to the icon's left
        background: "#fff",
        width: "auto",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "10px 10px",
        zIndex: 9999,
        lineHeight: "25px",
        color: "#222",
      }}
    >
      {/* Conditionally render menu items */}
      {hasVideo && (
        <div
          style={{ marginBottom: "5px", cursor: "pointer" }} >
         <span onClick={() => {
            toggleMenu();
            handleExtractGPS();
          }} >Extract GPS Data </span>&nbsp;
          <span style={{ fontSize: "14px", color: "#666" }}>
      (only for some GPS-enabled cameras)
    </span>
    <Tooltip textKey="extractgps" />
        </div> 
      )}
      {hasVideo && hasData && (
       <><hr/> <div
          style={{ marginBottom: "5px", cursor: "pointer" }}>
          <span onClick={() => {toggleMenu();handleSyncData();}}>Sync Data</span> <Tooltip textKey="syncData" />
        </div>
        </>
      )}
      {/* You could add more actions if desired */}
    </div>
  )}</div>
  <input
    type="text"
    className="save-run-input"
    value={runName}
    onChange={(e) => setRunName(e.target.value)}
    placeholder={placeholderName}
     />

  {/* Upload to YouTube Checkbox */}
  <label className="save-run-checkbox">
    <input
      type="checkbox"
      checked={uploadToYouTube}
      onChange={(e) => setUploadToYouTube(e.target.checked)}
    />
    Upload Videos<Tooltip textKey="uploadToYouTube" />
  </label>

  {/* Buttons */}
  <button className="save-run-btn" onClick={handleSaveRun} disabled={isProcessing}>
    {isProcessing ? "Processing..." : "Save Run"} ({creditCosts.SAVE_RUN}⚡)
  </button>
  <button
    style={{ marginLeft: "10px", padding: "10px", backgroundColor: "transparent", color: "#999", border: "none", borderRadius: "5px" }}
    onClick={handleFullscreenToggle}
  >
    <FontAwesomeIcon icon={faExpand} onClick={handleFullscreenToggle} />
  </button>
  { hasVideo && (
    <FontAwesomeIcon
                    icon={faTimes}
                    className="tooltip-close-btn"
                    onClick={unloadVideo}
                  />
)}

</div>)}

<div ref={fullScreenContainerRef} style={{ 
  position: "relative",    
  width: isFullscreen ? "100%" : "auto",
  height: isFullscreen ? "100%" : "auto" }}>
  {/* Video Section */}
  {hasVideo && (
    <div className="video-section" 
    style={{
      height: isFullscreen ? "100%" : "auto",

    }}
    >
{selectedVideoFile && (
  <video
    ref={videoRef}
    controls
    playsInline
    webkit-playsinline="true"
    style={{ display: "block", width: "100%", marginBottom: isFullscreen ? "0" : "10px" }}
    onPlay={() => {
    if (videosLinked) {
      syncVideoPlay(dashboardId); // ✅ Sync all videos when playing
    }
  }}
  onPause={() => {
    if (videosLinked) {
      syncVideoPause(dashboardId); // ✅ Sync all videos when pausing
    }
  }}
  onTimeUpdate={handleTimeUpdate}
  onSeeked={handleSeeked}
  />
)}
      {localYoutubeUrl && (
       <div
       ref={fullScreenContainerRef}
       style={{
         position: "relative",
         width: isFullscreen ? "100%" : "auto",
         height: isFullscreen ? "100%" : "450px",
       }}
     >
      <object
          data={`https://www.youtube.com/embed/${extractYouTubeVideoID(runData.youtubeUrl)}?...`}
          type="text/html"
          style={{
            display: "block",
            width: "100%",
            height: isFullscreen ? "100%" : "100%",
            marginBottom: isFullscreen ? "0" : "10px",
          }}
          id={`youtube-object-${dashboardId}`}
        />
        </div>
      )}
    </div>
  )}

   {/* Graph Canvas – Always Rendered */}
   <div
    style={
      isFullscreen
        ? {
          position: "absolute",
          bottom: "130px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "500px",
          height: "300px",
          background: "transparent",
          zIndex: 1500,
          padding: "5px",
          borderRadius: "4px",
        }
        : { marginTop: "10px" }
    }
  >
   
{/* Tab Header */}
<div
    className="tab-header"
    style={{
      borderBottom: "1px solid #ccc",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <span
      onClick={() => setActiveTab("graph")}
      style={{
        padding: "10px 20px",
        cursor: "pointer",
        color: activeTab === "graph" ? "#fff" : "#888",
        borderBottom: activeTab === "graph" ? "3px solid #000" : "none",
      }}
    >
      Graph
    </span>
    <span
      onClick={() => setActiveTab("metrics")}
      style={{
        padding: "10px 20px",
        cursor: "pointer",
        color: activeTab === "metrics" ? "#fff" : "#888",
        borderBottom: activeTab === "metrics" ? "3px solid #000" : "none",
      }}
    >
      Stats
    </span>
    { hasData && (
     <div style={{ position: "block",width: "100%", height: "50px",textAlign: "right", margin: "10px"  }}><FontAwesomeIcon
                    icon={faTimes}
                    className="tooltip-close-btn"
                    onClick={unloadData}
                  /></div>
)}
  </div>



    
    { (telemetryData.length > 0 && activeTab === "graph") && (
  <div
    style={
      isFullscreen
        ? {
            position: "absolute",
            bottom: "10px",
            left: "10px",
            width: "500px",
            height: "300px",
            background: "rgba(255,255,255,0)", // 50% opaque
            zIndex: 1500,
            padding: "5px",
            borderRadius: "4px",
          }
        : { marginTop: "10px" }
    }
  >
    <canvas id={`combinedGraph-${dashboardId}`}></canvas>
  </div>
)}
  </div>


  {/* Fullscreen Exit Button */}
  {isFullscreen && (
    <button
      onClick={handleFullscreenToggle}
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        zIndex: 2000,
        padding: "8px",
        backgroundColor: "#dc3545",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
      }}
    >
      <FontAwesomeIcon icon={faMinimize} /> Exit Fullscreen
    </button>
  )}
</div>

  
{(!hasData || !hasVideo) && (
<div id="drop-area"
  className="drop-area"
  onDragOver={handleDragOver}
  onDragLeave={handleDragLeave}
  onDrop={handleDrop}
  onClick={() => document.getElementById(`file-input-${dashboardId}`).click()}
>
{(!hasVideo && !hasData) && (
    <p>Drag/drop a video or data file (CSV, GPX, FIT) here or click to select.</p>
  )}

  {(hasData && !hasVideo) && (
    <p>Data is loaded. Drag/drop or click to select a video file.</p>
  )}

  {(!hasData && hasVideo) && (
    <p>Video is loaded. Drag/drop or click to select a data file.</p>
  )}

  {(hasData && hasVideo) && (
    <p>Video & data both loaded. Drop a new file to replace them or click to select.</p>
  )}

  {/* File Input */}
  <input
    id={`file-input-${dashboardId}`}
    type="file"
    accept="video/*,.csv"
    style={{ display: 'none' }}
    onChange={(e) => {
  const file = e.target.files[0];
  if (!file) return;

  if (file.type.startsWith('video/')) {
    setSelectedVideoFile(file);

    // Ensure videoRef is available before setting the src
    if (videoRef.current) {
      const url = URL.createObjectURL(file);
      videoRef.current.src = url;
      videoRef.current.style.display = 'block';
      if (youtubeIframeRef.current) {
        youtubeIframeRef.current.style.display = 'none';
      }
    } else {
      console.error("videoRef is not available yet.");
    }
  } else if (file.name.endsWith('.csv')) {
    handleTelemetryFile({ target: { files: [file] } });
  }
}}
  />
</div>
 )}

         
        </div>

        { telemetryData.length>0 && <div className="right-column">


          <div className="tab-container" style={{ marginTop: "10px" }}>
 

  {/* Tab Content */}
  <div className="tab-content" style={{ padding: "10px" }}>

    {activeTab === "metrics" && (
      <div className="metrics">
        <ul>
          <li>
            Total Distance: <span id={`total-distance-${dashboardId}`}>0</span> km
          </li>
          <li>
            Total Elevation Loss:{" "}
            <span id={`total-elevation-loss-${dashboardId}`}>0</span> m
          </li>
          <li>
            Total Time Spent Braking (Front):{" "}
            <span id={`total-time-braking-front-${dashboardId}`}>0</span> s
          </li>
          <li>
            Total Time Spent Braking (Rear):{" "}
            <span id={`total-time-braking-rear-${dashboardId}`}>0</span> s
          </li>
          <li>
            Total Time Spent Braking:{" "}
            <span id={`total-time-braking-${dashboardId}`}>0</span> s
          </li>
          <li>
            Proportion of Power (Front/Rear):{" "}
            <span id={`proportion-power-${dashboardId}`}>0% / 0%</span>
          </li>
          <li>
            Max Braking Force: <span id={`max-braking-force-${dashboardId}`}>0</span>{" "}
            W (<span id={`max-braking-type-${dashboardId}`}></span>)
          </li>
        </ul>
      </div>
    )}
  </div>
</div>



        </div>}
        {(isProcessing || message) && (
  <div
    className="toast-message"
    style={{
      position: "fixed",
      bottom: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      background: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      padding: "10px 20px",
      borderRadius: "8px",
      zIndex: 10000,
      fontSize: "16px",
      textAlign: "center",
    }}
  >
    <p>{isProcessing ? `📤 Uploading... ${uploadProgress}%` : message}</p>
  </div>
)}
      </div>
    </div>
  );
 
};

export default Dashboard;