import React, { useState } from "react";
import { FaQuestionCircle, FaRegSmile } from "react-icons/fa";

const FAQ = () => {
  const [faqOpenIndex, setFaqOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpenIndex((prev) => (prev === index ? null : index));
  };

  const faqItems = [
    {
      question: "⚙️ What is this tool?",
      answer:
        "This tool synchronizes video and different kinds of data (braking, GPS, speed, etc.). You can overlay your metrics directly on your videos to analyze your performance.",
    },
    {
      question: "🤔 What file formats are supported?",
      answer:
        "Our tool supports multiple data acquisition formats. You can use CSV files exported from our Brake Ace Desktop application—which include braking data along with GPS, speed, and other metrics—as well as CSV files exported from the BYB data acquisition system, which provide additional details such as suspension (fork and shock) positions, acceleration (X, Y, Z), and gyro data. For video, common formats like MP4 and AVI are accepted, allowing you to analyze performance from various sources seamlessly."
    },
    {
      question: "📹 How can I use data from my GoPro camera?",
      answer:
        "You can acquire data from your GoPro camera in two ways: by using our online extraction tool, or by using our desktop tool (download links provided below). The online tool extracts data directly from your uploaded file, while the desktop tool processes the file locally—ideal for large files and when you want faster performance without uploading large videos."
    },
    {
      question: "🛠 How can I use data from Brake Ace sensors?",
      answer:
        "Brake Ace sensor data is supported through CSV files exported from our Brake Ace Desktop application. These files contain essential braking data that can be overlaid on your videos, giving you a detailed analysis of your braking performance and overall riding dynamics."
    },
    {
      question: "🚀 How can I use data from the BYB data acquisition system?",
      answer:
        "The BYB data acquisition system offers more than traditional telemetry by including extra data points such as suspension information. Its CSV exports include fork and shock positions, along with acceleration and gyro data. This additional information allows you to analyze the behavior of your suspension system at various points along the track, enabling a more in-depth performance analysis."
    },
    {
      question: "🔄 How do I sync data with my video?",
      answer:
        "Use our 'Sync Data' tool to align the video timestamp with the start of your data acquisition, ensuring that your performance data and video playback are perfectly synchronized for accurate analysis."
    },
    {
      question: "💳 How are credits used?",
      answer:
        "Each analysis or video processing task consumes a specific number of credits. Your available credits update in real time so you always know your balance. Credits help cover our server and processing costs."
    },
    {
      question: "🛒 How do I purchase more credits?",
      answer:
        "Click the 'Purchase Credits' button in the sidebar to buy additional credits. The process is secure, handled by Stripe, and your credits are updated immediately upon purchase."
    },
    {
      question: "📁 How do I save and load runs?",
      answer:
        "Simply click the save button to store your run. You have the option to save your data locally or in the cloud. Use the search and sort features in the runs table to quickly find and select a specific run for detailed analysis."
    },
    {
      question: "📝 Can I view detailed metrics for each run?",
      answer:
        "Absolutely! Once you load a run, your dashboard displays detailed metrics such as speed, braking force, suspension positions, split comparisons, and more—providing a full breakdown of your performance."
    },
  ];

  return (
      <ul className="faq-list">
        {faqItems.map((item, index) => (
          <li key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFaq(index)}>
              <FaQuestionCircle style={{ marginRight: "5px" }} />
              {item.question}
            </div>
            {faqOpenIndex === index && (
              <div className="faq-answer">
                {item.answer}{" "}
                <span role="img" aria-label="smile">
                  <FaRegSmile />
                </span>
              </div>
            )}
          </li>
        ))}
      </ul>

  );
};

export default FAQ;