import React, { useContext, useRef, useState, useEffect } from "react";
import { MapContext } from "./MapProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFlagCheckered, faFlag } from "@fortawesome/free-solid-svg-icons";
import "../App.css";
import L from "leaflet";

let markerToggle = true; // module-level toggle

export const createOrUpdateMarker = (mapRef, gps, label, markerRef, isStart = true) => {
  console.log("🧭 Marker creation input", { gps, label, markerRef, mapRef });
  if (!mapRef?.current) {
    console.error("🛑 Map reference is invalid or not yet initialized.");
    return;
  }

  if (gps && gps.lat && gps.lon) {
    const flagIcon = L.divIcon({
      html: `<div style="
        background-color: ${isStart ? 'green' : 'red'};
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid white;
      "></div>`,
      iconAnchor: [6, 6],
    });

    const tooltipDirection = markerToggle ? "left" : "right";
    markerToggle = !markerToggle;

    if (!markerRef.current) {
      markerRef.current = L.marker([gps.lat, gps.lon], { icon: flagIcon })
        .bindTooltip(label, { permanent: true, direction: tooltipDirection })
        .addTo(mapRef.current);
    } else {
      markerRef.current.setLatLng([gps.lat, gps.lon]);
      markerRef.current.setTooltipContent(label);
    }
  } else {
    console.warn("⚠️ Invalid GPS data for marker creation", gps);
  }
};
/**
 * SegmentCreator
 */
const SegmentCreator = ({
  segment,
  onUpdateSegment,
  onDelete,
  videoRef,    // no longer used for time, but left in props to avoid breaking other code
  gpsData,
  color,
  // array of dashboards so we can display each one’s duration:
  dashboards = [],
  masterTime, // from MultiDashboard
}) => {
  const { mapRef } = useContext(MapContext);

  // Track whether start and end points have been set
  const [isStartSet, setIsStartSet] = useState(false);
  const [isEndSet, setIsEndSet] = useState(false);

  const startMarkerRef = useRef(null);
  const endMarkerRef = useRef(null);

  /**
   * Given a timestamp, find the closest GPS point in the master's telemetry.
   * This is how we get lat/lon for the master at the current time.
   */
  const getGPSDataForTimestamp = (timestamp) => {
    if (!gpsData || gpsData.length === 0) {
      console.warn("GPS data is empty or invalid");
      return null;
    }
    // Find whichever point's 'time' is closest to the provided timestamp
    return gpsData.reduce((prev, curr) =>
      Math.abs(curr.time - timestamp) < Math.abs(prev.time - timestamp)
        ? curr
        : prev
    );
  };

  const getFastestSegment = () => {
    let fastestTime = Infinity;
    let fastestDashId = null;
  
    dashboards.forEach((dash) => {
      const dashDuration = segment.durations?.[dash.id]?.duration;
      if (dashDuration && dashDuration < fastestTime) {
        fastestTime = dashDuration;
        fastestDashId = dash.id;
      }
    });
  
    return fastestDashId;
  };
  
  const fastestDashId = getFastestSegment();

  /**
   * Creates or updates a Leaflet marker for the start/finish on the master map
   */
  /**
   * Handle "Set Start" click:
   * 1) Use masterTime to find the lat/lon
   * 2) Store it in segment.startGPS
   * 3) If segment.endFrame is set, compute the master’s partial duration
   * 4) Call onUpdateSegment => triggers recalc
   * 5) Place a Leaflet marker
   */
  const handleSetStart = () => {
    console.log("handleSetStart clicked for segment:", segment.id);
    if (masterTime == null) {
      console.error("No valid masterTime available.");
      return;
    }
    const masterPoint = getGPSDataForTimestamp(masterTime);
    if (!masterPoint) {
      console.warn("No valid GPS data found for the current timestamp:", masterTime);
      return;
    }
    let newDuration = segment.duration || 0;
    if (segment.endFrame !== null) {
      newDuration = Math.abs(segment.endFrame - masterTime);
    }
    const updatedSegment = {
      ...segment,
      startFrame: masterTime,
      startGPS: { lat: masterPoint.lat, lon: masterPoint.lon },
      duration: newDuration,
    };
    onUpdateSegment(updatedSegment);
    createOrUpdateMarker(
      mapRef,
      { lat: masterPoint.lat, lon: masterPoint.lon },
      `${segment.name} Start`,
      startMarkerRef,
      true
    );
    setIsStartSet(true);
  };

  /**
   * Handle "Set Finish" click:
   * 1) Use masterTime to find the lat/lon
   * 2) Store in segment.endGPS
   * 3) If segment.startFrame is set, compute partial duration
   * 4) Trigger onUpdateSegment => recalc
   * 5) Place a Leaflet marker
   */
  const handleSetEnd = () => {
    console.log("handleSetEnd clicked for segment:", segment.id);
    if (masterTime == null) {
      console.error("No valid masterTime available.");
      return;
    }
    const masterPoint = getGPSDataForTimestamp(masterTime);
    if (!masterPoint) {
      console.warn("No valid GPS data found for the current timestamp:", masterTime);
      return;
    }
    let newDuration = segment.duration || 0;
    if (segment.startFrameFrame !== null) {
      newDuration = Math.abs(masterTime - segment.startFrame);
    }
    const updatedSegment = {
      ...segment,
      endFrame: masterTime,
      endGPS: { lat: masterPoint.lat, lon: masterPoint.lon },
      duration: newDuration,
    };
    onUpdateSegment(updatedSegment);
    createOrUpdateMarker(
      mapRef,
      { lat: masterPoint.lat, lon: masterPoint.lon },
      `${segment.name} End`,
      endMarkerRef,
      false
    );
    setIsEndSet(true);
  };
  // const handleSetEnd = () => {
  //   console.log("handleSetFinish clicked for segment:", segment.id);

  //   if (masterTime == null) {
  //     console.error("No valid masterTime available.");
  //     return;
  //   }
  //   const masterPoint = getGPSDataForTimestamp(masterTime);
  //   if (!masterPoint) {
  //     console.warn("No valid GPS data found for the current timestamp:", masterTime);
  //     return;
  //   } else {
  //     console.log("masterPoint", masterPoint);
  //   }

  //   let newDuration = segment.duration || 0;
  //   if (segment.startFrame !== null) {
  //     newDuration = Math.abs(masterTime - segment.startFrame);
  //   }

  //   const updatedSegment = {
  //     ...segment,
  //     endFrame: masterTime,
  //     endGPS: { lat: masterPoint.lat, lon: masterPoint.lon },
  //     duration: newDuration, // master duration
  //   };

  //   console.log("Updated Segment after setFinish:", updatedSegment);
  //   onUpdateSegment(updatedSegment);

  //   createOrUpdateMarker(
  //     { lat: masterPoint.lat, lon: masterPoint.lon },
  //     `${segment.name} End`,
  //     endMarkerRef
  //   );
  //   setIsEndSet(true);
  // };

  /**
   * Delete the segment + remove any markers
   */
  const handleDelete = () => {
    if (startMarkerRef.current && mapRef?.current) {
      mapRef.current.removeLayer(startMarkerRef.current);
      startMarkerRef.current = null;
    }
    if (endMarkerRef.current && mapRef?.current) {
      mapRef.current.removeLayer(endMarkerRef.current);
      endMarkerRef.current = null;
    }
    onDelete();
  };

  useEffect(() => {
    if (segment.startGPS && !startMarkerRef.current) {
      createOrUpdateMarker(
        mapRef,
        segment.startGPS,
        `${segment.name} Start`,
        startMarkerRef,
        true
      );
      setIsStartSet(true);
    }
  
    if (segment.endGPS && !endMarkerRef.current) {
      createOrUpdateMarker(
        mapRef,
        segment.endGPS,
        `${segment.name} End`,
        endMarkerRef,
        false
      );
      setIsEndSet(true);
    }
  }, [segment.id, segment.startGPS, segment.endGPS]);

  return (
    <div
      style={{
        padding: "5px 13px 5px 12px",
        border: `2px solid ${color}`,
        borderRadius: "8px",
        margin: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#f9f9f9",
        width: "auto",
      }}
    >
      {/* Segment Name Input */}
      <input
        type="text"
        value={segment.name || ""}
        placeholder="Segment name"
        onChange={(e) => onUpdateSegment({ ...segment, name: e.target.value })}
        className="segment-creator-input"
      />

      {/* Per-Dashboard Durations */}
      <div style={{ marginLeft: "10px", display: "flex", flexDirection: "row" }}>
        {dashboards.map((dash) => {
          const dashEntry = segment.durations?.[dash.id];
          const dashDur = dashEntry?.duration || 0;
          const isFastest = dash.id === fastestDashId;

          return (
            <div
              key={dash.id}
              style={{
                padding: "5px",
                backgroundColor: dash.color,
                color: "black",
                fontSize: "0.9em",
                marginRight: "5px",
                marginLeft: "5px",
                width: "auto",
              }}
            >
              {dashDur.toFixed(2)}s
              {isFastest && (
         <span
            style={{
              marginLeft: "7px",
              padding: "2px",
              width: "10px",
              height: "10px",
              backgroundColor: "#33ff33",
              borderRadius: "0%",
            }}
          >+</span>
        )}
            </div>
          );
        })}
      </div>

      <div>
  {/* Start Button */}
<button
  className="button-segment-creator"
  style={{
    backgroundColor: isStartSet ? "#ccc" : "black",
    color: isStartSet ? "black" : "white",
    border: `2px solid ${color}`,
    marginRight: "5px",
  }}
  onClick={handleSetStart}
  title="Set Start"
>
  <FontAwesomeIcon
    icon={faFlag}
    size="lg"
    style={{ color: isStartSet ? "black" : "white", marginRight: "5px" }}
  />
</button>

{/* End Button */}
<button
  className="button-segment-creator"
  style={{
    backgroundColor: isEndSet ? "#ccc" : "black",
    color: isEndSet ? "black" : "white",
    border: `2px solid ${color}`,
    marginRight: "5px",
  }}
  onClick={handleSetEnd}
  title="Set End"
>
  <FontAwesomeIcon
    icon={faFlagCheckered}
    size="lg"
    style={{ color: isEndSet ? "black" : "white", marginRight: "5px" }}
  />
</button>

        {/* Delete Button */}
        <button onClick={handleDelete} className="button-segment-creator-trash">
          <FontAwesomeIcon icon={faTrash} size="lg" style={{ color: "#fff" }} />
        </button>
      </div>
    </div>
  );
};

export default SegmentCreator;