import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content-stacked">
        <p style={{ marginBottom: '8px', color: '#bbb' }}>
          © 2025 Victorise. All rights reserved.
        </p>

        <div className="footer-links">
          <a href="https://victorise.com" className="footer-link">Homepage</a>
          <span className="footer-divider">|</span>
          <a href="https://victorise.webflow.io/coaching" className="footer-link">Coaching</a>
          <span className="footer-divider">|</span>
          <a href="https://www.calendly.com/jeromelacote" className="footer-link">Free 30min Consultation</a>
        </div>

        <p className="footer-note">
          Questions or bugs?{' '}
          <a
            href="mailto:contact@victorise.com"
            style={{ color: '#bbb', textDecoration: 'underline' }}
          >
            Reach out
          </a>{' '}
          or email us directly at <a href="mailto:contact@victorise.com" className="footer-link">contact@victorise.com</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;