import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, setDoc, getDoc, addDoc, collection } from "firebase/firestore";
import { FaUser, FaUserPlus } from "react-icons/fa";
import "../App.css";
import { auth, db } from "../firebase";

const Login = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");

  // Check if "?signup=true" is present in the URL and set SignUp state
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("signup") === "true") {
      setIsSignUp(true);
    }
  }, [location]);

  if (isAuthenticated) {
    return <Navigate to="/homepage" />;
  }

  const exampleRunIds = [
    "mail@jeromelacote.com_1741311905726",
    "mail@jeromelacote.com_1741302325508",
    "mail@jeromelacote.com_1741302116503",
    "mail@jeromelacote.com_1741301997047",
  ]; // Replace with actual IDs

const prepopulateExampleRuns = async (userId) => {
  try {
    const batch = [];

    for (const runId of exampleRunIds) {
      const runRef = doc(db, "runs", runId);
      const runSnap = await getDoc(runRef);

      if (runSnap.exists()) {
        const exampleRunData = runSnap.data();
        const newRunRef = doc(collection(db, "runs"));

        batch.push(
          setDoc(newRunRef, {
            ...exampleRunData,
            userId, // Assign new user
            sharedWith: [], // Reset shared list
            timestamp: new Date(), // Update timestamp
          })
        );
      }
    }

    await Promise.all(batch);
  } catch (error) {
    console.error("Error prepopulating example runs:", error);
  }
};

  const handleSignUp = async () => {
    setError("");
    if (!email || !password) {
      setError("Email and password are required");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        email,
        username,
        accepted: true,
        credits: 100,
      });

      await addDoc(collection(db, "mail"), {
        to: "mail@jeromelacote.com",
        message: {
          subject: "New user signup!",
          text: `A new user signed up with email: ${email}`,
        },
      });

      await prepopulateExampleRuns(user.uid);

      alert("Account created! You have been granted 100 credits.");
      setIsSignUp(false);
      setEmail("");
      setPassword("");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleLogin = async () => {
    setError("");
    if (!email || !password) {
      setError("Email and password are required");
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        setError("No user profile found.");
        return;
      }
      if (docSnap.data().accepted) {
        setIsAuthenticated(true);
      } else {
        setError("Account not accepted yet.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        {isSignUp ? <FaUserPlus className="login-icon" /> : <FaUser className="login-icon" />}
        <h2 className="login-title">{isSignUp ? "Create Your Account" : "Login to Your Account"}</h2>
      </div>

      {error && <p className="error-text">{error}</p>}

      {isSignUp && (
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="login-input"
        />
      )}

      <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="login-input" />
      <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="login-input" />

      {isSignUp ? (
        <button onClick={handleSignUp} className="primary-btn">Sign Up</button>
      ) : (
        <button onClick={handleLogin} className="primary-btn">Login</button>
      )}

      <p className="login-switch">
        {isSignUp ? "Already have an account?" : "No account yet?"}{" "}
        <span className="login-switch-link" onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp ? "Login" : "Create one"}
        </span>
      </p>
    </div>
  );
};

export default Login;