// Header.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../firebase'; 
import { useAuth } from '../contexts/AuthContext';
import Tooltip from "./Tooltip";
import { FaBars, FaTimes, FaAngleDown, FaVideo } from "react-icons/fa";
import '../App.css';

function Header() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (!user) {
      setUserData(null);
      return;
    }
    const docRef = doc(db, 'users', user.uid);
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        setUserData(snapshot.data());
      }
    });
    return () => unsubscribe();
  }, [user]);

  const handleBuyCredits = () => {
    navigate('/buy-credits');
    setMobileMenuOpen(false);
  };

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (err) {
      console.error('Sign out error:', err);
    }
  };

  // Safely check for user before accessing its email.
  const nameToDisplay = userData?.name || (user ? user.email : "Guest");
  const userCredits = userData?.credits ?? 0;

  return (
    <header className="header-container">
      <div className="logo">
        <a href="/">
          <img src="logo.png" alt="Victorise Logo" />
        </a>
        <span className="beta-label">Beta 0.24</span>
      </div>

      {/* Desktop version: visible only on larger screens */}
{/* Desktop version: visible only on larger screens */}
<div className="header-user-info desktop-only" style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '10px', width: '100%' }}>
  {user ? (
    <>
      {/* Tools Dropdown remains visible even for logged-in users */}
      <div className="tools-dropdown" style={{ position: 'relative', marginRight: '20px', width: 'auto' }}>
        <button style={styles.dropdownBtn}>
          Our Tools <FaAngleDown style={{ marginLeft: '5px' }} />
        </button>
        <div className="dropdown-content" style={styles.dropdownContent}>
          <a href="http://www.chronocult.com" style={styles.dropdownLink}>⏱️ Easy Timer</a>
          <a href="/multi-dashboard" style={styles.dropdownLink}>📈 Video / Data </a>
        </div>
      </div>
      <button style={styles.buyCreditsBtn} onClick={handleBuyCredits}>
        Buy Credits <Tooltip textKey="buyCredits" className="tooltip-icon" />
      </button>
      <button style={styles.logoutBtn} onClick={handleSignOut}>
        Sign Out
      </button>
      <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#222', borderRadius: '5px', marginLeft: '10px', padding: '6px 12px' }} onClick={handleBuyCredits}>
        <span>{userCredits} ⚡</span>
      </div>
    </>
  ) : (
    // If not logged in, show a Sign In button that redirects to the Login page.
    <button
      onClick={() => navigate("/login")}
      style={{
        padding: '6px 12px',
        backgroundColor: '#13A1A8',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
    >
      Sign In
    </button>
  )}
</div>

      {/* Mobile version: hamburger menu icon (bright grey) */}
      <div className="mobile-only" style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <button 
          onClick={() => setMobileMenuOpen(true)} 
          style={{ background: 'transparent', border: 'none', fontSize: '1.8em', cursor: 'pointer', color: '#ccc' }}
        >
          <FaBars />
        </button>
      </div>

      {/* Mobile menu overlay */}
      {isMobileMenuOpen && (
        <div className="mobile-menu-overlay">
          <div className="mobile-menu">
            <button 
              onClick={() => setMobileMenuOpen(false)}
              style={{
                background: 'transparent',
                border: 'none',
                fontSize: '1.5em',
                color: '#fff',
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer'
              }}
            >
              <FaTimes />
            </button>
            <div style={{ padding: '20px', color: '#fff', display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {user ? (
    <>
      <span style={{ display: 'block' }}>
        Hello, <strong>{nameToDisplay}</strong> | Credits: {userCredits}
        <Tooltip textKey="credits" />
      </span>
      <button style={styles.buyCreditsBtn} onClick={handleBuyCredits}>
        Buy Credits
      </button>
      <button style={styles.logoutBtn} onClick={handleSignOut}>
        Sign Out
      </button>
    </>
  ) : (
    <button
      onClick={() => navigate("/login")}
      style={{
        padding: '6px 12px',
        backgroundColor: '#13A1A8',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
    >
      Sign In
    </button>
  )}
              
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

const styles = {
  buyCreditsBtn: {
    padding: '6px 12px',
    backgroundColor: '#13A1A8',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  logoutBtn: {
    padding: '6px 12px',
    backgroundColor: '#d9534f',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  dropdownBtn: {
    padding: '6px 12px',
    backgroundColor: 'transparent',
    color: '#fff',
    border: '0px solid #fff',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.9em'
  },
  dropdownContent: {
    with: '300px',
    display: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: '#222',
    color: '#000',
    minWidth: '160px',
    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
    zIndex: 9000,
    padding: '8px 0',
  },
  dropdownLink: {
    display: 'block',
    padding: '8px 12px',
    textDecoration: 'none',
    color: '#ddd',
    fontSize: '0.9em'
  }
};

export default Header;