import React, { createContext, useRef, useEffect } from "react";
import L from "leaflet";
import '../App.css';

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) {
      // Initialize the map
      mapRef.current = L.map("map").setView([34.2218186,-116.8925244,1456], 16);
      // Add Esri World Imagery as the satellite tile layer
      L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",).addTo(mapRef.current);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  const addMarker = (lat, lon, label, color = "blue", direction = "right") => {
    if (mapRef.current) {
      const flagIcon = L.divIcon({
        html: `<div style="background-color: ${color}; width: 10px; height: 10px; border-radius: 50%;"></div>`,
        iconAnchor: [5, 5],
      });

      const marker = L.marker([lat, lon], { icon: flagIcon })
        .bindTooltip(label, { permanent: true, direction })
        .addTo(mapRef.current);

      return marker; // Return the marker if further interaction is needed
    }
  };

  return (
    <MapContext.Provider value={{ mapRef, addMarker }}>
      {children}
    </MapContext.Provider>
  );
};