// AdminPage.js
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const AdminPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const adminUID = "lXK7NXhBv4NpeJiNMPLp2cXNHou1";

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: "username", direction: "asc" });
  const [totalRuns, setTotalRuns] = useState(0);
  const [totalYouTubeRuns, setTotalYouTubeRuns] = useState(0);

  useEffect(() => {
    if (!user || user.uid !== adminUID) {
      navigate("/"); // Redirect non-admin users to homepage
      return;
    }

    const fetchUsers = async () => {
      setLoading(true);
      try {
        const usersRef = collection(db, "users");
        const usersSnapshot = await getDocs(usersRef);
        let totalRunsCount = 0;
        let totalYouTubeRunsCount = 0;

        const usersData = await Promise.all(
          usersSnapshot.docs.map(async (docSnap) => {
            const userData = docSnap.data();
            const runsRef = collection(db, "runs");
            const runsQuery = query(runsRef, where("userId", "==", docSnap.id));
            const runsSnapshot = await getDocs(runsQuery);

            const userRuns = runsSnapshot.docs.length;
            const userYouTubeRuns = runsSnapshot.docs.filter(
              (run) => run.data().youtubeUrl
            ).length;

            totalRunsCount += userRuns;
            totalYouTubeRunsCount += userYouTubeRuns;

            return {
              id: docSnap.id,
              username: userData.username || "No username",
              email: userData.email || "No email",
              credits: userData.credits ?? 0,
              runs: userRuns,
              youtubeRuns: userYouTubeRuns,
            };
          })
        );

        setUsers(usersData);
        setTotalRuns(totalRunsCount);
        setTotalYouTubeRuns(totalYouTubeRunsCount);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [user]);

  // Sorting function
  const sortUsers = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedUsers = [...users].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setUsers(sortedUsers);
  };

  return (
    <div className="homepage-container">
      <div className="homepage-main">
        <h1>🛠 Admin Panel</h1>
        <p>Manage users and their data.</p>

        <h2>Users ({users.length})</h2>

        {loading ? (
          <p>🔄 Loading users...</p>
        ) : users.length > 0 ? (
          <table className="run-table">
            <thead>
              <tr>
                <th onClick={() => sortUsers("username")}>
                  Username {sortConfig.key === "username" ? (sortConfig.direction === "asc" ? "🔼" : "🔽") : ""}
                </th>
                <th onClick={() => sortUsers("email")}>
                  Email {sortConfig.key === "email" ? (sortConfig.direction === "asc" ? "🔼" : "🔽") : ""}
                </th>
                <th onClick={() => sortUsers("credits")}>
                  Credits {sortConfig.key === "credits" ? (sortConfig.direction === "asc" ? "🔼" : "🔽") : ""}
                </th>
                <th onClick={() => sortUsers("runs")}>
                  Runs Created ({totalRuns}) {sortConfig.key === "runs" ? (sortConfig.direction === "asc" ? "🔼" : "🔽") : ""}
                </th>
                <th onClick={() => sortUsers("youtubeRuns")}>
                  Runs on YouTube ({totalYouTubeRuns}) {sortConfig.key === "youtubeRuns" ? (sortConfig.direction === "asc" ? "🔼" : "🔽") : ""}
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.credits}</td>
                  <td>{user.runs}</td>
                  <td>{user.youtubeRuns}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No users found.</p>
        )}
      </div>
    </div>
  );
};

export default AdminPage;